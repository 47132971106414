import type { ISaleVehicle, ISaleVehicleSearch } from '../../models/entities/SaleVehicle';
import type {
	ISaleVehicleGetByIdParams,
	ISaleVehicleSearchParams,
	ISaleVehicleSendSaleFormParams,
	ISaleVehicleShareParams,
} from '../../models/serviceParams/SaleVehicleParams';
import { Api } from '../../modules/shared/api';
import { SaleVehicleEndPoints } from '../endPoints/SaleVehicleEndPoints';

export const saleVehicleServiceSearch = (model: ISaleVehicleSearchParams) => {
	return Api.get<ISaleVehicleSearch, ISaleVehicleSearchParams>(SaleVehicleEndPoints.SEARCH, model);
};

export const saleVehicleServiceGetById = (model: ISaleVehicleGetByIdParams) => {
	return Api.get<ISaleVehicle, ISaleVehicleGetByIdParams>(SaleVehicleEndPoints.GET_BY_ID, model);
};

export const saleVehicleServiceShare = (model: ISaleVehicleShareParams) => {
	return Api.post<boolean, ISaleVehicleShareParams>(SaleVehicleEndPoints.SHARE, model);
};

export const saleVehicleServiceSendSaleForm = (model: ISaleVehicleSendSaleFormParams) => {
	return Api.post<number, ISaleVehicleSendSaleFormParams>(SaleVehicleEndPoints.SEND_SALE_FORM, model);
};
