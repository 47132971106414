import React from 'react';

import classNames from 'classnames';
import type { ControllerProps } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';

import { useInputFieldNumberProps } from './hooks';
import type { IInputNumberProps } from './types';

export const InputFieldNumber: React.FC<IInputNumberProps<number | null>> = ({
	component: Component = 'input',
	innerRef,
	...rest
}) => {
	/* Hooks */
	const { onChange, onBlur, validation, ...props } = useInputFieldNumberProps(rest);
	const { name } = props;

	const { control, formState, getFieldState } = useFormContext();

	const { field } = useController({
		name,
		control,
		rules: validation as ControllerProps['rules'],
	});

	const notifyChange = (value: number | null) => {
		field.onChange(value);
		if (onChange) {
			onChange(value);
		}
	};

	/* Handlers */
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { valueAsNumber } = event.target;
		const newValue = isNaN(valueAsNumber) ? null : valueAsNumber;
		notifyChange(newValue);
	};

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const { value } = field;
		if (value && props.step) {
			const step = Number(props.step);
			// eslint-disable-next-line init-declarations
			let newValue: number;
			if (Number.isInteger(step)) {
				/**
				 * If 'step' is defined and is an integer, adjust
				 * the value to the nearest lower multiple of 'step'
				 */
				newValue = Math.floor(value / step) * step;
			} else {
				newValue = parseFloat(value.toFixed(2));
			}
			if (newValue !== value) {
				notifyChange(newValue);
			}
		}

		field.onBlur();
		if (onBlur) {
			onBlur(event);
		}
	};

	/* Constants */
	const { error } = getFieldState(name, formState);
	const componentClassName = classNames('form-control', {
		'is-invalid': Boolean(error),
	});

	let value: string | number = field.value ?? '';
	if (typeof value === 'number' && isNaN(value)) {
		value = '';
	}

	const handleRef = (ref: HTMLInputElement | null) => {
		field.ref(ref);
		if (innerRef) {
			innerRef.current = ref;
		}
	};

	return (
		<Component
			{...props}
			className={componentClassName}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			ref={handleRef}
			value={value}
		/>
	);
};
