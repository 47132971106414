export enum SaleVehicleEndPoints {
	/**
	 * Sale vehicle search
	 * @host `/saleVehicle/search/`
	 */
	SEARCH = '/saleVehicle/search/',
	/**
	 * Get sale vehicle by id
	 * @host `/saleVehicle/getbyid/`
	 */
	GET_BY_ID = '/saleVehicle/getbyid/',
	/**
	 * Share sale vehicle by id
	 * @host `/saleVehicle/share/`
	 */
	SHARE = '/saleVehicle/share/',
	/**
	 * Send sale form vehicle
	 * @host `/saleVehicle/sendsaleform/`
	 */
	SEND_SALE_FORM = '/saleVehicle/sendsaleform/',
}
