import type { FieldError } from 'react-hook-form';

/**
 * Method that returns the description of an error of a form field.
 * @param name - The name of the field
 * @param error - The error object of react-hook-form
 * @returns The description of the error
 */
export const getErrorDescription = (name: string, error: FieldError) => {
	if (error.message) {
		return error.message;
	}

	if (!error.type) {
		return `Error: ${name}`;
	}

	if (error.type === 'required') {
		return `Required`;
	}

	if (error.ref?.hasAttribute?.(error.type)) {
		const value = error.ref.getAttribute?.(error.type);
		if (value !== undefined) {
			return `${error.type} must be ${value}`;
		}
	}
	return `Error: ${error.type}`;
};
