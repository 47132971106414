import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';

export interface IAccidentGetByVehicleParams extends IServiceBaseParams {
	plateNumber: string;
}

export const accidentGetByVehicleService = (model: IAccidentGetByVehicleParams) => {
	return Api.get<IAccident[], IAccidentGetByVehicleParams>(AccidentEndpoints.GET_BY_VEHICLE, model);
};
