import { MetaModelEndpoints } from './MetaModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IMetaModel } from '../entities/MetaModel';
import type { MetaModelImageType } from '../types/MetaModelImageType';

export interface IMetaModelDeleteImageParams extends IServiceBaseParams {
	id: number;
	imageType: MetaModelImageType;
}

export const metaModelServiceDeleteImage = (model: IMetaModelDeleteImageParams) => {
	return Api.post<IMetaModel, IMetaModelDeleteImageParams>(MetaModelEndpoints.DELETE_IMAGE, model);
};
