import { createSlice } from '@reduxjs/toolkit';

import type { ISupplier } from '@crac/core/models/entities/Supplier';
import {
	supplierClear,
	supplierInsertCarrier,
	supplierModifyCarrier,
	supplierSearch,
} from '@crac/core/redux/actions/SupplierActions';
import type { ISupplierReducerState } from '@crac/core/redux-store/reducersState/suppliers';

type ICarrierReducerState = Omit<ISupplierReducerState, 'suppliers' | 'supplierGetAllRequest'> & {
	carriers: ISupplier[];
};

const initialState: ICarrierReducerState = {
	supplierInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	carriers: [],
};

const supplierCarrierSlice = createSlice({
	name: 'CarrierSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder.addCase(supplierSearch.fulfilled, (state, action) => {
			return {
				...state,
				supplierSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				carriers: action.payload,
			};
		});
		// INSERT CARRIER
		builder
			.addCase(supplierInsertCarrier.pending, (state) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierInsertCarrier.fulfilled, (state, action) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					carriers: [...state.carriers, action.payload].sort((acc, curr) =>
						acc.code.localeCompare(curr.code),
					),
				};
			})
			.addCase(supplierInsertCarrier.rejected, (state, action) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});

		// MODIFY CARRIER
		builder
			.addCase(supplierModifyCarrier.pending, (state) => {
				return {
					...state,
					supplierModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierModifyCarrier.fulfilled, (state, action) => {
				return {
					...state,
					supplierModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					carriers: [
						...state.carriers.filter((carrier) => carrier.code !== action.payload.code),
						action.payload,
					].sort((acc, curr) => acc.code.localeCompare(curr.code)),
				};
			})
			.addCase(supplierModifyCarrier.rejected, (state, action) => {
				return {
					...state,
					supplierModifyRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});

		// CLEAR
		builder.addCase(supplierClear, (state) => {
			return {
				...state,
				carriers: [],
			};
		});
	},
});

export const carrierReducer = supplierCarrierSlice.reducer;
