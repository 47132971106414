import type { FC } from 'react';

import { Link } from 'react-router-dom';

import type { TextsTranlationType } from '@crac/i18n';
import { getStaticTexts } from '@crac/i18n';

import { LoginAcceptPrivacyPolicy, LoginTitle } from './Login.styled';
import { LoginContainer } from './LoginContainer';
import { LoginSwitchLanguage } from './LoginSwitchLanguage';
import type { IInitialStateProps, ILoginProps, ISubmitButtonConfig } from './types';
import { InputField } from '../../../../../crac-form/src/field';
import { Form } from '../../../../../crac-form/src/form';
import { Button } from '../../../external/reactstrap/button';
import { Label } from '../../../external/reactstrap/label';
import { BaseComponentValues } from '../../../types/IComponentBaseProps';
import { Logo } from '../logo';

const defaultSubmitButton = (texts: TextsTranlationType): ISubmitButtonConfig => ({
	align: 'end',
	block: false,
	text: texts.login,
});

export const Login: FC<ILoginProps> = ({
	inputs,
	onForgotPassword,
	onShowPrivacyPolicy,
	onSubmit,
	showLogo = true,
	submitButton,
	subtitle,
	renderCustomElement,
	locale = BaseComponentValues.locale as string,
	onChangeLanguage,
	showSwitchLanguage,
	isLoading,
	className,
	renderAfterSubmitButton,
	initialState,
}) => {
	const texts = getStaticTexts(locale as string);

	const submitButtonProps: ISubmitButtonConfig = {
		...defaultSubmitButton(texts),
		...submitButton,
	};

	const { inputPassword, inputUserName } = inputs;
	const formInitialState: IInitialStateProps = {
		acceptPrivacyPolicy: initialState?.acceptPrivacyPolicy ?? false,
		ethicalCode: initialState?.ethicalCode ?? false,
		[inputPassword ? inputPassword.name : 'password']: initialState?.password ?? undefined,
		[inputUserName.name]: initialState?.userName ?? undefined,
	};

	if (!inputs) {
		return <p className="text-danger">Debes configurar el input username y password</p>;
	}

	const EthicalCodeLabel = (
		<span className="w-100">
			{texts.acceptEthicalCode}&nbsp;
			<Link target="_blank" to={texts.ethicalCodeLink}>
				{texts.ethicalCode}
			</Link>
		</span>
	);

	const PrivacyPolicyLabel = (
		<div>
			{texts.acceptPrivacyPolicy}&nbsp;
			<Button className="m-0 p-0 pb-1 border-0" color="link" onClick={onShowPrivacyPolicy}>
				{`${texts.privacyPolicy}`.toLocaleLowerCase()}
			</Button>
		</div>
	);

	return (
		<LoginContainer className={className} data-test="login">
			{showLogo ? <Logo /> : null}
			{subtitle ? <LoginTitle className="text-muted">{subtitle}</LoginTitle> : null}
			<Form
				aria-label="login-form"
				autoComplete="off"
				data-test="form"
				initialValues={formInitialState}
				onSubmit={onSubmit}
			>
				{({ formState: { isValid, isDirty, isSubmitting } }) => (
					<>
						{inputUserName ? (
							<InputField
								autoFocus
								data-test="field-userName"
								label={inputUserName.label}
								name={inputUserName.name}
								placeholder={inputUserName.placeholder}
								validation={{ required: true }}
							/>
						) : null}
						{inputPassword ? (
							<InputField
								autoComplete="new-password"
								data-test="field-password"
								label={inputPassword.label}
								name={inputPassword.name}
								placeholder={inputPassword.placeholder}
								type="password"
								validation={{ required: true }}
							/>
						) : null}
						{onForgotPassword ? (
							<Button className="mb-1 p-0" color="link" onClick={onForgotPassword}>
								Forgot password?
							</Button>
						) : null}

						{onShowPrivacyPolicy ? (
							<LoginAcceptPrivacyPolicy className="policy-check">
								<InputField
									className="mb-0 mt-1"
									label={PrivacyPolicyLabel}
									name="acceptPrivacyPolicy"
									type="checkbox"
									validation={{ required: true }}
								/>
								<InputField
									className="mt-1"
									label={EthicalCodeLabel}
									name="ethicalCode"
									type="checkbox"
									validation={{ required: true }}
								/>
							</LoginAcceptPrivacyPolicy>
						) : null}

						{renderCustomElement ? renderCustomElement() : null}

						<div className={`d-flex justify-content-${submitButtonProps.align}`}>
							<Button
								color="primary"
								data-test="button-submit"
								isBlock={submitButtonProps.block}
								isDisabled={!isValid || !isDirty || isSubmitting || isLoading}
								type="submit"
							>
								{submitButtonProps.icon ? (
									<i className={`fa fa-fw fa-${submitButtonProps.icon}`} />
								) : null}
								{submitButtonProps.text ? submitButtonProps.text : texts.login}
								{isSubmitting || isLoading ? <i className="fa fa-fw fa-pulse fa-spinner" /> : null}
							</Button>
						</div>
						{renderAfterSubmitButton ?? null}
						{showSwitchLanguage ? (
							<LoginSwitchLanguage currentLocale={locale as string} onChange={onChangeLanguage} />
						) : null}
					</>
				)}
			</Form>
		</LoginContainer>
	);
};
