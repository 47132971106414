export enum SupplierEndPoints {
	/**
	 * Supplier search by code
	 * @host `/supplier/getByCode/`
	 */
	GET_BY_CODE = '/supplier/getByCode/',

	/**
	 * Supplier search
	 * @host `/supplier/search/`
	 */
	SEARCH = '/supplier/search/',

	/**
	 * Supplier get workshop by vehicle
	 * @host `/supplier/GetWorkshopByVehicle/`
	 */
	GET_ALL = '/supplier/GetWorkshopByVehicle/',

	/**
	 * Supplier insert
	 * @host `/supplier/insertWorkshop`
	 */
	INSERT_WORKSHOP = '/supplier/insertWorkshop',

	/**
	 * Supplier modify
	 * @host `/supplier/modifyWorkshop`
	 */
	MODIFY_WORKSHOP = '/supplier/modifyWorkshop',

	/**
	 * Supplier insert carrier
	 * @host `/supplier/insertCarrier`
	 */
	INSERT_CARRIER = '/supplier/insertCarrier',
	/**
	 * Supplier modify carrier
	 * @host `/supplier/modifyCarrier`
	 */
	MODIFY_CARRIER = '/supplier/modifyCarrier',
}
