import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderSearchParams extends IServiceBaseParams {
	customer?: string;
	orderDateFrom?: string;
	orderDateTo?: string;
}

/**
 * Search for vehicle sale orders
 * @param params - Parameters for the search of sale orders.
 * @returns A promise that resolves to the inserted sale orders.
 */
export const saleOrderSearchService = (params: ISaleOrderSearchParams) => {
	return Api.get<ISaleOrder[], ISaleOrderSearchParams>(SaleOrderEndPoints.SEARCH, params);
};
