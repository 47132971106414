import { bookingRoutes } from './booking/routes';
import { companyRoutes } from './company/routes';
import { cracFormRoutes } from './cracForm/routes';
import { customerRoutes } from './customer/routes';
import { dashboardRoutes } from './dashboard/routes';
import { employeeRoutes } from './employee/routes';
import { fleetRoutes } from './fleet/routes';
import { itRoutes } from './it/routes';
import { managementRoutes } from './management/routes';
import { pricingRoutes } from './pricing/routes';
import { saleRoutes } from './sale/routes';
import { vehicleRoutes } from './vehicle/routes';
import { workshopRoutes } from './workshop/routes';

export const routes = {
	...bookingRoutes,
	...companyRoutes,
	...cracFormRoutes,
	...customerRoutes,
	...dashboardRoutes,
	...employeeRoutes,
	...fleetRoutes,
	...managementRoutes,
	...pricingRoutes,
	...saleRoutes,
	...vehicleRoutes,
	...workshopRoutes,
	...itRoutes,
};
