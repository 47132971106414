import { AccidentEndpoints } from './AccidentEndpoints';
import type { AccidentDocument } from '../../../../modules/workshop/accident/types/AccidentDocumentType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';
import type { AccidentMailType } from '../types/AccidentMailType';

export interface IAccidentSendMailParams extends IServiceBaseParams {
	accidentCode: string;
	accidentMailType?: AccidentMailType;
	files?: AccidentDocument[];
	key: number;
	plateNumber: string;
	provider: string;
}

export const accidentSendMailService = (model: IAccidentSendMailParams) => {
	return Api.post<IAccident, IAccidentSendMailParams>(AccidentEndpoints.SEND_MAIL, model);
};
