import { createSlice } from '@reduxjs/toolkit';

import {
	saleServiceDelete,
	saleServiceInsert,
	saleServiceModify,
	saleServiceSearch,
	saleServicesClear,
} from '@crac/core/modules/sale/saleService/state/SaleServiceActions';
import type { ISaleServiceStateType } from '@crac/core/modules/sale/saleService/state/SaleServiceStateType';

const initialState: ISaleServiceStateType = {
	saleServices: [],
	saleServiceDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const saleServiceSlice = createSlice({
	name: 'SaleServiceSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//* Search
		builder.addCase(saleServiceSearch.pending, (state) => {
			return {
				...state,
				saleServiceSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceSearch.rejected, (state, action) => {
			return {
				...state,
				saleServiceSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceSearch.fulfilled, (state, action) => {
			return {
				...state,
				saleServices: action.payload,
				saleServiceSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* Insert
		builder.addCase(saleServiceInsert.pending, (state) => {
			return {
				...state,
				saleServiceInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceInsert.rejected, (state, action) => {
			return {
				...state,
				saleServiceInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceInsert.fulfilled, (state, action) => {
			return {
				...state,
				saleServices: [...state.saleServices, action.payload],
				saleServiceInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* Modify
		builder.addCase(saleServiceModify.pending, (state) => {
			return {
				...state,
				saleServiceModifyRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceModify.rejected, (state, action) => {
			return {
				...state,
				saleServiceModifyRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceModify.fulfilled, (state, action) => {
			return {
				...state,
				saleServices: state.saleServices.map((item) => (item.id === action.payload.id ? action.payload : item)),
				saleServiceModifyRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* Delete
		builder.addCase(saleServiceDelete.pending, (state) => {
			return {
				...state,
				saleServiceDeleteRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceDelete.rejected, (state, action) => {
			return {
				...state,
				saleServiceDeleteRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceDelete.fulfilled, (state, action) => {
			return {
				...state,
				saleServices: state.saleServices.filter((item) => item.id !== action.payload.id),
				saleServiceDeleteRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* Clear
		builder.addCase(saleServicesClear, (state) => {
			return {
				...state,
				saleServices: [],
			};
		});
	},
});

export const saleServiceReducer = saleServiceSlice.reducer;
