import { createSlice } from '@reduxjs/toolkit';

import {
	accidentDelete,
	accidentGetByAccidentPart,
	accidentGetByBooking,
	accidentGetByVehicle,
	accidentGetPartData,
	accidentInsert,
	accidentModify,
	accidentSearch,
	accidentSendMail,
	accidentsClear,
} from '@crac/core/modules/workshop/accident/state/actions/AccidentActions';
import type { IAccidentStateType } from '@crac/core/modules/workshop/accident/state/stateType/AccidentStateType';
import { linkClear, linkGetByAccidentPart } from '@crac/core/redux/actions/LinkActions';

const initialState: IAccidentStateType = {
	accident: undefined,
	accidentBooking: undefined,
	accidentCustomer: undefined,
	accidentDeleteRequest: { inProgress: false, messages: [], ok: true },
	accidentGetByAccidentPartRequest: { inProgress: false, messages: [], ok: true },
	accidentGetByBookingRequest: { inProgress: false, messages: [], ok: true },
	accidentGetByVehicleRequest: { inProgress: false, messages: [], ok: true },
	accidentGetPartDataRequest: { inProgress: false, messages: [], ok: true },
	accidentInsertRequest: { inProgress: false, messages: [], ok: true },
	accidentModifyRequest: { inProgress: false, messages: [], ok: true },
	accidents: [],
	accidentSearchRequest: { inProgress: false, messages: [], ok: true },
	accidentSendEmailRequest: { inProgress: false, messages: [], ok: true },
	accidentVehicle: undefined,
	documents: [],
	documentsRequest: { inProgress: false, messages: [], ok: true },
};

const accidentSlice = createSlice({
	name: 'AccidentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(accidentsClear, () => {
			return initialState;
		});
		// GET BY ACCIDENT PART
		builder
			.addCase(accidentGetByAccidentPart.pending, (state) => {
				return {
					...state,
					accidents: [],
					accidentGetByAccidentPartRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByAccidentPart.fulfilled, (state, action) => {
				return {
					...state,
					accidents: action.payload,
					accidentGetByAccidentPartRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByAccidentPart.rejected, (state) => {
				return {
					...state,
					accidentGetByAccidentPartRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
				};
			});
		// GET BY VEHICLE
		builder
			.addCase(accidentGetByVehicle.pending, (state) => {
				return {
					...state,
					accidents: [],
					accidentGetByVehicleRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					accidents: action.payload,
					accidentGetByVehicleRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByVehicle.rejected, (state, action) => {
				return {
					...state,
					accidentGetByVehicleRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY BOOKING
		builder
			.addCase(accidentGetByBooking.pending, (state) => {
				return {
					...state,
					accidents: [],
					accidentGetByBookingRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					accidents: action.payload,
					accidentGetByBookingRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByBooking.rejected, (state, action) => {
				return {
					...state,
					accidentGetByBookingRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(accidentSearch.pending, (state) => {
				return {
					...state,
					accidentSearchRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentSearch.fulfilled, (state, action) => {
				return {
					...state,
					accidents: action.payload,
					accidentSearchRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentSearch.rejected, (state, action) => {
				return {
					...state,
					accidentSearchRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// GET PART DATA
		builder
			.addCase(accidentGetPartData.pending, (state) => {
				return {
					...state,
					accidentBooking: undefined,
					accidentCustomer: undefined,
					accidentVehicle: undefined,
					accidentGetPartDataRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentGetPartData.fulfilled, (state, action) => {
				return {
					...state,
					accidentBooking: action.payload?.booking,
					accidentCustomer: action.payload?.customer,
					accidentVehicle: action.payload?.vehicle,
					accidentGetPartDataRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentGetPartData.rejected, (state, action) => {
				return {
					...state,
					accidentGetPartDataRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// INSERT
		builder
			.addCase(accidentInsert.pending, (state) => {
				return {
					...state,
					accident: undefined,
					accidentInsertRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentInsert.fulfilled, (state, action) => {
				return {
					...state,
					accident: action.payload,
					accidents: [...state.accidents, action.payload],
					accidentInsertRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentInsert.rejected, (state, action) => {
				return {
					...state,
					accidentInsertRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// MODIFY
		builder
			.addCase(accidentModify.pending, (state) => {
				return {
					...state,
					accident: undefined,
					accidentModifyRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentModify.fulfilled, (state, action) => {
				const { plateNumber, documentNumber, key } = action.payload.accident;
				const currentAccidents = state.accidents.map((accident) =>
					accident.plateNumber === plateNumber &&
					accident.documentNumber === documentNumber &&
					accident.key === key
						? action.payload.accident
						: accident,
				);

				return {
					...state,
					accident: action.payload.accident,
					accidents: currentAccidents,
					accidentModifyRequest: { inProgress: false, messages: [], ok: true },
					documents: [...state.documents, ...action.payload.documents],
				};
			})
			.addCase(accidentModify.rejected, (state, action) => {
				return {
					...state,
					accidentModifyRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// DELETE
		builder
			.addCase(accidentDelete.pending, (state) => {
				return {
					...state,
					accidentDeleteRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentDelete.fulfilled, (state, action) => {
				const { plateNumber, documentNumber, key } = action.payload;
				const currentAccidents = state.accidents.filter(
					(accident) =>
						!(
							accident.plateNumber === plateNumber &&
							accident.documentNumber === documentNumber &&
							accident.key === key
						),
				);

				return {
					...state,
					accidents: currentAccidents,
					accidentDeleteRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentDelete.rejected, (state, action) => {
				return {
					...state,
					accidentDeleteRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// SEND EMAIL
		builder
			.addCase(accidentSendMail.pending, (state) => {
				return {
					...state,
					accidentSendEmailRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentSendMail.fulfilled, (state, action) => {
				const { plateNumber, documentNumber, key } = action.payload;
				const currentAccidents = state.accidents.map((accident) =>
					accident.plateNumber === plateNumber &&
					accident.documentNumber === documentNumber &&
					accident.key === key
						? action.payload
						: accident,
				);
				return {
					...state,
					accidents: currentAccidents,
					accidentSendEmailRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentSendMail.rejected, (state, action) => {
				return {
					...state,
					accidentSendEmailRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// LINK GET BY ACCIDENT
		builder
			.addCase(linkGetByAccidentPart.pending, (state) => {
				return {
					...state,
					documents: [],
					documentsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByAccidentPart.fulfilled, (state, action) => {
				return {
					...state,
					documents: action.payload,
					documentsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByAccidentPart.rejected, (state, action) => {
				return {
					...state,
					documentsRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		builder.addCase(linkClear, (state) => {
			return {
				...state,
				documents: [],
			};
		});
	},
});

export const accidentReducer = accidentSlice.reducer;
