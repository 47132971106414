import { createSyncAction } from '../../../shared/state/createAction';
import { createAsyncAction } from '../../../shared/state/createAsyncAction';
import type { IVehicle } from '../../entities/Vehicle';
import type { IVehicleTelemetry } from '../../entities/VehicleConnectedTelemetry';
import type {
	IConnectedVehicleSearchParams,
	IVehicleEnrollParams,
	IVehicleEnrollResponse,
	IVehicleGetTelemetryParams,
	IVehicleUnEnrollParams,
	IVehicleUnenrollResponse,
} from '../../services/VehicleServiceConnectedVehicles';
import {
	vehicleServiceConnectedVehicleSearch,
	vehicleServiceEnroll,
	vehicleServiceGetTelemetry,
	vehicleServiceUnEnroll,
} from '../../services/VehicleServiceConnectedVehicles';

/**
 * Enrolls vehicles.
 */
export const vehicleEnroll = createAsyncAction<IVehicleEnrollResponse, IVehicleEnrollParams>(
	'vehicle/vehicleEnroll',
	vehicleServiceEnroll,
);

/**
 * Unenrolls vehicles.
 */
export const vehicleUnEnroll = createAsyncAction<IVehicleUnenrollResponse, IVehicleUnEnrollParams>(
	'vehicle/vehicleUnEnroll',
	vehicleServiceUnEnroll,
);

/**
 * Retrieves telemetry data for connected vehicle.
 *
 * @param payload - The payload containing the telemetry data.
 */
export const vehiclesGetTelemetry = createAsyncAction<IVehicleTelemetry, IVehicleGetTelemetryParams>(
	'vehicle/getTelemetry',
	vehicleServiceGetTelemetry,
);

/**
 * Clears the search for connected vehicles.
 */
export const vehiclesGetTelemetryClear = createSyncAction('vehicle/getTelemetry/clear');

/**
 * Search for connected vehicles.
 *
 * @param {IVehicle[]} payload - The payload containing the search results.
 * @param {IConnectedVehicleSearchParams} meta - The metadata for the search.
 */
export const connectedVehicleSearch = createAsyncAction<IVehicle[], IConnectedVehicleSearchParams>(
	'connectedVehicles/search',
	vehicleServiceConnectedVehicleSearch,
);

/**
 * Clears the search for connected vehicles.
 */
export const connectedVehicleClear = createSyncAction('connectedVehicles/clear');
