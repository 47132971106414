import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const SaleOrder = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrder'));
const SaleOrderNew = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderNew'));
const SaleOrderModify = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderModify'));
const SaleOrderLineInsert = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderLineInsert'));

export const saleOrderRoutes: { [key: number]: IRouteConfig } = {};

saleOrderRoutes[routesIdMap.SaleOrder] = {
	component: SaleOrder,
	exact: true,
	id: routesIdMap.SaleOrder,
	name: 'Sale orders',
	menuText: 'Orders',
	path: '/sale/orders',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'shopping-bag',
};

saleOrderRoutes[routesIdMap.SaleOrderNew] = {
	component: SaleOrderNew,
	exact: true,
	id: routesIdMap.SaleOrderNew,
	name: 'New sale orders',
	menuText: 'New sale orders',
	path: '/sale/orders/new',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.SaleOrderModify] = {
	component: SaleOrderModify,
	exact: true,
	id: routesIdMap.SaleOrderModify,
	name: 'Modify sale orders',
	path: '/sale/orders/:id',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.SaleOrderLineInsert] = {
	component: SaleOrderLineInsert,
	exact: true,
	id: routesIdMap.SaleOrderLineInsert,
	name: 'Insert sale orders lines',
	path: '/sale/orders/:id/lines-insert',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};
