import { VehicleEndPoints } from './VehicleEndPoints';
import { Api } from '../../shared/api';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../entities/Vehicle';

export interface IVehicleRequestInsuranceCancellationParams extends IServiceBaseParams {
	plateNumbers: string[];
}

export const vehicleServiceRequestInsuranceCancellation = (model: IVehicleRequestInsuranceCancellationParams) => {
	return Api.post<IVehicle[], IVehicleRequestInsuranceCancellationParams>(
		VehicleEndPoints.REQUEST_INSURANCE_CANCELLATION,
		model,
	);
};
