import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import { ServiceResponse } from '../../../../shared/types/ServiceResponse';
import type { IMetaModel } from '../../entities/MetaModel';
import type { IMetaModelDeleteImageParams } from '../../services/MetaModelDeleteImageService';
import { metaModelServiceDeleteImage } from '../../services/MetaModelDeleteImageService';
import type { IMetaModelDeleteParams } from '../../services/MetaModelDeleteService';
import { metaModelDeleteService } from '../../services/MetaModelDeleteService';
import { type IMetaModelInsertParams, metaModelInsertService } from '../../services/MetaModelInsertService';
import { type IMetaModelModifyParams, metaModelModifyService } from '../../services/MetaModelModifyService';
import type { IMetaModelImageItem, IMetaModelSaveImageParams } from '../../services/MetaModelSaveImageService';
import { metaModelSaveImageService } from '../../services/MetaModelSaveImageService';
import { type IMetaModelSearchParams, metaModelSearchService } from '../../services/MetaModelSearchService';
import { MetaModelImageType } from '../../types/MetaModelImageType';

const mapImages = (image?: string, saleImage?: string) =>
	[
		image ? { image, imageType: MetaModelImageType.Image } : undefined,
		saleImage ? { image: saleImage, imageType: MetaModelImageType.SaleImage } : undefined,
	].filter(Boolean) as IMetaModelImageItem[];

const resolveImages = async (params: IMetaModelSaveImageParams) => {
	const response = await metaModelSaveImageService(params);

	if (!response.ok || !response.data) {
		return new ServiceResponse<IMetaModel>(false, null, response.errors);
	}
	return new ServiceResponse<IMetaModel>(true, response.data);
};

//* SEARCH
export const metaModelSearch = createAsyncAction<IMetaModel[], IMetaModelSearchParams>(
	'metaModel/search',
	metaModelSearchService,
);

interface IMetaModelInsertAndSaveImageParams extends IMetaModelInsertParams {
	image?: string;
	saleImage?: string;
}
//* INSERT
export const metaModelInsert = createAsyncAction<IMetaModel, IMetaModelInsertAndSaveImageParams>(
	'metaModel/insert',
	async (params: IMetaModelInsertAndSaveImageParams) => {
		const { brand, name, image, saleImage } = params;

		const insertResponse = await metaModelInsertService({ brand, name });

		if (!insertResponse.ok || !insertResponse.data) {
			return new ServiceResponse<IMetaModel>(false, null, insertResponse.errors);
		}

		const images = mapImages(image, saleImage);
		if (images.length) {
			return await resolveImages({ images, id: insertResponse.data.id });
		}

		return new ServiceResponse<IMetaModel>(true, insertResponse.data);
	},
);

interface IMetaModelModifyAndSaveImageParams extends IMetaModelModifyParams {
	image?: string;
	saleImage?: string;
	modifyOnlyImage?: boolean;
}
//* MODIFY
export const metaModelModify = createAsyncAction<IMetaModel, IMetaModelModifyAndSaveImageParams>(
	'metaModel/modify',
	async (params: IMetaModelModifyAndSaveImageParams) => {
		const { brand, name, id, image, modifyOnlyImage, saleImage } = params;
		// Only modifies image
		if (modifyOnlyImage && (image || saleImage)) {
			const images = mapImages(image, saleImage);
			if (images.length) {
				return await resolveImages({ images, id });
			}
		}

		// Modifies metaModel
		const modifyResponse = await metaModelModifyService({ id, brand, name });

		if (!modifyResponse.ok || !modifyResponse.data) {
			return new ServiceResponse<IMetaModel>(false, null, modifyResponse.errors);
		}

		// Modifies image
		const images = mapImages(image, saleImage);
		if (images.length) {
			return await resolveImages({ images, id });
		}

		return new ServiceResponse<IMetaModel>(true, modifyResponse.data);
	},
);

//* SAVE IMAGE
export const metaModelSaveImage = createAsyncAction<IMetaModel, IMetaModelSaveImageParams>(
	'metaModel/saveImage',
	metaModelSaveImageService,
);

//* DELETE
export const metaModelDelete = createAsyncAction<IMetaModel, IMetaModelDeleteParams>(
	'metaModel/delete',
	metaModelDeleteService,
);

export const metaModelDeleteImage = createAsyncAction<IMetaModel, IMetaModelDeleteImageParams>(
	'metaModel/deleteImage',
	metaModelServiceDeleteImage,
);

//* CLEAR
export const metaModelsClear = createSyncAction('metaModels/clear');
