import { createSyncAction } from '../../../shared/state/createAction';
import { createAsyncAction } from '../../../shared/state/createAsyncAction';
import type { ISaleMargin } from '../entities/SaleMargin';
import type { ISaleMarginDeactivateParams } from '../services/SaleMarginDeactivateService';
import { saleMarginDeactivateService } from '../services/SaleMarginDeactivateService';
import type { ISaleMarginGetMarginParams } from '../services/SaleMarginGetMarginService';
import { saleMarginGetMarginService } from '../services/SaleMarginGetMarginService';
import type { ISaleMarginInsertParams } from '../services/SaleMarginInsertService';
import { saleMarginInsertService } from '../services/SaleMarginInsertService';
import type { ISaleMarginReactivateParams } from '../services/SaleMarginReactivateService';
import { saleMarginReactivateService } from '../services/SaleMarginReactivateService';
import type { ISaleMarginSearchParams } from '../services/SaleMarginSearchService';
import { saleMarginSearchService } from '../services/SaleMarginSearchService';

//* GET MARGIN
export const saleMarginGetMargin = createAsyncAction<ISaleMargin, ISaleMarginGetMarginParams>(
	'saleMargin/getMargin',
	saleMarginGetMarginService,
);

//* SEARCH
export const saleMarginSearch = createAsyncAction<ISaleMargin[], ISaleMarginSearchParams>(
	'saleMargin/search',
	saleMarginSearchService,
);

//* INSERT
export const saleMarginInsert = createAsyncAction<ISaleMargin[], ISaleMarginInsertParams>(
	'saleMargin/insert',
	saleMarginInsertService,
);

//* DEACTIVATE
export const saleMarginDeactivate = createAsyncAction<ISaleMargin[], ISaleMarginDeactivateParams>(
	'saleMargin/deactivate',
	saleMarginDeactivateService,
);

//* REACTIVATE
export const saleMarginReactivate = createAsyncAction<ISaleMargin[], ISaleMarginReactivateParams>(
	'saleMargin/reactivate',
	saleMarginReactivateService,
);

//* CLEAR
export const saleMarginsClear = createSyncAction('saleMargins/clear');
