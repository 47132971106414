import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	vehiclePreparation,
	vehiclePreparationAdd,
	vehiclePreparationClear,
	vehiclePreparationRemove,
	vehiclePreparationUpdate,
} from '@crac/core/redux/actions/VehicleActions';
import type { IPreparationReducerState } from '@crac/core/redux-store/reducersState/vehicle/PreparationReducerState';
const initialState: IPreparationReducerState = {
	load: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	preparationRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	preparationVehiclesSelected: [],
	vehicle: undefined,
	vehicleLine: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};
const preparationSlice = createSlice({
	name: 'VehiclePreparationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// PREPARATION STATE
		builder
			.addCase(vehiclePreparation.pending, (state) => {
				return {
					...state,
					preparationRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehiclePreparation.fulfilled, (state) => {
				return {
					...state,
					preparationRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehiclePreparation.rejected, (state, action) => {
				return {
					...state,
					preparationRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// ADD
		builder.addCase(vehiclePreparationAdd, (state, action) => {
			return {
				...state,
				preparationVehiclesSelected: [
					...state.preparationVehiclesSelected,

					...action.payload
						.filter(
							(vehicle) =>
								state.preparationVehiclesSelected.findIndex(
									(item) => item.frameNumber === vehicle.frameNumber,
								) === -1,
						)
						.map((vehicle) => ({ ...vehicle, litersRefueled: 0 })),
				],
			};
		});
		// REMOVE
		builder.addCase(vehiclePreparationRemove, (state, action) => {
			return {
				...state,
				preparationVehiclesSelected: [
					...state.preparationVehiclesSelected.filter(
						(vehicle) => vehicle.plateNumber !== action.payload.plateNumber,
					),
				],
			};
		});
		// UPDATE
		builder.addCase(vehiclePreparationUpdate, (state, action) => {
			return {
				...state,
				preparationVehiclesSelected: action.payload || [],
			};
		});
		// CLEAR
		builder.addCase(vehiclePreparationClear, (state) => {
			return {
				...state,
				preparationVehiclesSelected: [],
			};
		});
	},
});

export const preparationReducer = preparationSlice.reducer;
