import { SaleOrderLineEndPoints } from './SaleOrderLineEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrderLine } from '../entities/SaleOrderLine';

export interface ISaleOrderLineGetByOrderIdParams extends IServiceBaseParams {
	orderId: number;
}

export const saleOrderLineGetByOrderIdService = (params: ISaleOrderLineGetByOrderIdParams) => {
	return Api.get<ISaleOrderLine[], ISaleOrderLineGetByOrderIdParams>(SaleOrderLineEndPoints.GET_BY_ORDER_ID, params);
};
