/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
	fleetVehicleModifyChangeAgreedRepurchaseDate,
	fleetVehicleModifyChangeBrandParking,
	fleetVehicleModifyChangeCarrier,
	fleetVehicleModifyChangeDeFleetDate,
	fleetVehicleModifyChangeFleetTypology,
	fleetVehicleModifyChangeNextITVDate,
	fleetVehicleModifyChangePlateNumber,
	fleetVehicleModifyChangeRegistrationDate,
	fleetVehicleModifyChangeRentEndDate,
	fleetVehicleModifyChangeRentStartDate,
	fleetVehicleModifyChangeSaleAmount,
	fleetVehicleModifyChangeUnregisterTrafficDate,
	fleetVehicleModifyChangeVehicleModel,
	fleetVehicleModifyClear,
	fleetVehicleModifyRequestInsuranceCancellation,
	fleetVehicleModifySearch,
	fleetVehicleModifyVehicleGroup,
} from '@crac/core/redux/actions/FleetVehicleModifyActions';
import { vehicleSearch, vehicleSearchClear } from '@crac/core/redux/actions/VehicleActions';
import type { IFleetVehicleModifyReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetVehicleModifyReducerState';

const initialState: IFleetVehicleModifyReducerState = {
	fleetVehicleModifySearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyVehicleGroupRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeDeFleetDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeAgreedRepurchaseDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeNextITVDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeRentStartDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeRentEndDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeUnregisterTrafficDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeFleetTypologyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeCarrierRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeRegistrationDateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeBrandParkingRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeSaleAmountRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyRequestInsuranceCancellationRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangeVehicleModelRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModifyChangePlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicles: [],
};

const vehicleModifySlice = createSlice({
	name: 'FleetVehicleModify',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// VEHICLE GROUP
		builder
			.addCase(fleetVehicleModifyVehicleGroup.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyVehicleGroupRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyVehicleGroup.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyVehicleGroupRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyVehicleGroup.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyVehicleGroupRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE DEFLEET DATE
		builder
			.addCase(fleetVehicleModifyChangeDeFleetDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeDeFleetDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeDeFleetDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeDeFleetDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyChangeDeFleetDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeDeFleetDateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE AGREED REPURCHASE DATE
		builder
			.addCase(fleetVehicleModifyChangeAgreedRepurchaseDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeAgreedRepurchaseDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeAgreedRepurchaseDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeAgreedRepurchaseDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyChangeAgreedRepurchaseDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeAgreedRepurchaseDateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE NEXT ITV DATE
		builder
			.addCase(fleetVehicleModifyChangeNextITVDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeNextITVDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeNextITVDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeNextITVDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyChangeNextITVDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeNextITVDateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE RENT START DATE
		builder
			.addCase(fleetVehicleModifyChangeRentStartDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeRentStartDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeRentStartDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeRentStartDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyChangeRentStartDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeRentStartDateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE RENT END DATE
		builder
			.addCase(fleetVehicleModifyChangeRentEndDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeRentEndDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeRentEndDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeRentEndDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyChangeRentEndDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeRentEndDateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE UNREGISTER TRAFFIC DATE
		builder
			.addCase(fleetVehicleModifyChangeUnregisterTrafficDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeUnregisterTrafficDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeUnregisterTrafficDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeUnregisterTrafficDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) => {
						return action.payload.find((current) => current.plateNumber === item.plateNumber) ?? item;
					}),
				};
			})
			.addCase(fleetVehicleModifyChangeUnregisterTrafficDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeUnregisterTrafficDateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* CHANGE FLEET TYPOLOGY */
		builder
			.addCase(fleetVehicleModifyChangeFleetTypology.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeFleetTypologyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeFleetTypology.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeFleetTypologyRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeFleetTypology.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeFleetTypologyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		/* CHANGE CARRIER */
		builder
			.addCase(fleetVehicleModifyChangeCarrier.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeCarrierRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeCarrier.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeCarrierRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeCarrier.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeCarrierRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		// CHANGE REGISTRATION DATE
		builder
			.addCase(fleetVehicleModifyChangeRegistrationDate.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeRegistrationDateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeRegistrationDate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeRegistrationDateRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeRegistrationDate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeRegistrationDateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		// CHANGE BRAND PARKING
		builder
			.addCase(fleetVehicleModifyChangeBrandParking.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeBrandParkingRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeBrandParking.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeBrandParkingRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeBrandParking.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeBrandParkingRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		// CHANGE SALE AMOUNT
		builder
			.addCase(fleetVehicleModifyChangeSaleAmount.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeSaleAmountRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeSaleAmount.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeSaleAmountRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeSaleAmount.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeSaleAmountRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		// REQUEST INSURANCE CANCELLATION
		builder
			.addCase(fleetVehicleModifyRequestInsuranceCancellation.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyRequestInsuranceCancellationRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyRequestInsuranceCancellation.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyRequestInsuranceCancellationRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyRequestInsuranceCancellation.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyRequestInsuranceCancellationRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		/* CHANGE MODEL */
		builder
			.addCase(fleetVehicleModifyChangeVehicleModel.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangeVehicleModelRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeVehicleModel.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeVehicleModelRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeVehicleModel.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangeVehicleModelRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map(
						(item) => action.payload.find(({ plateNumber }) => plateNumber === item.plateNumber) ?? item,
					),
				};
			});
		/**
		 * CHANGE PLATE NUMBER
		 */
		builder
			.addCase(fleetVehicleModifyChangePlateNumber.pending, (state) => {
				return {
					...state,
					fleetVehicleModifyChangePlateNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangePlateNumber.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangePlateNumberRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangePlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModifyChangePlateNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: state.vehicles.map((item) =>
						action.payload.frameNumber === item.frameNumber ? action.payload : item,
					),
				};
			});

		/*
		 * SEARCH
		 */
		builder
			.addMatcher(isAnyOf(fleetVehicleModifySearch.pending, vehicleSearch.pending), (state) => {
				return {
					...state,
					fleetVehicleModifySearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addMatcher(isAnyOf(fleetVehicleModifySearch.fulfilled, vehicleSearch.fulfilled), (state, action) => {
				return {
					...state,
					fleetVehicleModifySearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: action.payload,
				};
			})
			.addMatcher(isAnyOf(fleetVehicleModifySearch.rejected, vehicleSearch.rejected), (state, action) => {
				return {
					...state,
					fleetVehicleModifySearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});

		// CLEAR
		builder.addMatcher(isAnyOf(fleetVehicleModifyClear, vehicleSearchClear), (state) => {
			return {
				...state,
				vehicles: [],
			};
		});
	},
});

export const vehicleModifyReducer = vehicleModifySlice.reducer;
