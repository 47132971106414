import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const BrandParkings = lazyWithRetry(() => import('./pages/BrandParkings'));
const BrandParkingsNew = lazyWithRetry(() => import('./pages/BrandParkingsNew'));
const BrandParkingsModify = lazyWithRetry(() => import('./pages/BrandParkingsModify'));

export const brandParkingsRoutes: { [key: number]: IRouteConfig } = {};

brandParkingsRoutes[routesIdMap.FleetBrandParkings] = {
	component: BrandParkings,
	exact: true,
	id: routesIdMap.FleetBrandParkings,
	name: 'Brand parkings',
	path: '/fleet/brand-parkings',
	permissions: [PermissionsType.FleetBrandParking],
	menuText: 'Brand parkings',
	icon: 'product-hunt',
	childrens: [routesIdMap.FleetBrandParkingsNew, routesIdMap.FleetBrandParkingsModify],
};

brandParkingsRoutes[routesIdMap.FleetBrandParkingsNew] = {
	component: BrandParkingsNew,
	exact: true,
	id: routesIdMap.FleetBrandParkingsNew,
	isChild: true,
	name: 'Brand parkings new',
	path: '/fleet/brand-parkings/new',
	permissions: [PermissionsType.FleetBrandParking],
	menuText: 'Brand parkings new',
	icon: 'plus',
};

brandParkingsRoutes[routesIdMap.FleetBrandParkingsModify] = {
	component: BrandParkingsModify,
	exact: true,
	id: routesIdMap.FleetBrandParkingsModify,
	isChild: true,
	name: 'Brand parkings modify',
	path: '/fleet/brand-parkings/:id',
	permissions: [PermissionsType.FleetBrandParking],
	icon: 'pencil',
};
