import { saleVehicleServiceSendSaleForm } from '../../data/services/SaleVehicleService';
import type { ISaleVehicleSendSaleFormParams } from '../../models/serviceParams/SaleVehicleParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Sends a new sale form.
 *
 * @param {ISaleVehicleSendSaleFormParams} params - Parameters detailing the sale form to be sended.
 * @returns {Promise<void>} - A promise that resolves after the sale form is successfully sended.
 */
export const saleVehicleSaleForm = createAsyncAction<number, ISaleVehicleSendSaleFormParams>(
	'saleVehicle/saleForm',
	saleVehicleServiceSendSaleForm,
);
