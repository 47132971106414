import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { supplierClear, supplierGetAll, supplierSearch } from '@crac/core/redux/actions/SupplierActions';
import type { ISupplierReducerState } from '@crac/core/redux-store/reducersState/suppliers';

const initialState: ISupplierReducerState = {
	supplierGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	suppliers: [],
};

const supplierSlice = createSlice({
	name: 'SupplierSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET ALL
		builder
			.addCase(supplierGetAll.pending, (state) => {
				return {
					...state,
					supplierGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierGetAll.fulfilled, (state, action) => {
				return {
					...state,
					supplierGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					suppliers: deepCopy(action.payload),
				};
			})
			.addCase(supplierGetAll.rejected, (state, action) => {
				return {
					...state,
					supplierGetAllRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) ?? [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(supplierSearch.pending, (state) => {
				return {
					...state,
					supplierSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierSearch.fulfilled, (state, action) => {
				return {
					...state,
					supplierSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					suppliers: action.payload,
				};
			})
			.addCase(supplierSearch.rejected, (state, action) => {
				return {
					...state,
					supplierSearchRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});

		// CLEAR
		builder.addCase(supplierClear, (state) => {
			return {
				...state,
				suppliers: [],
			};
		});
	},
});

export const supplierReducer = supplierSlice.reducer;
