import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Carrier = lazyWithRetry(() => import('./pages/Carrier'));
const CarrierNew = lazyWithRetry(() => import('./pages/CarrierNew'));
const CarrierModify = lazyWithRetry(() => import('./pages/CarrierModify'));

export const carrierRoutes: { [key: number]: IRouteConfig } = {};

carrierRoutes[routesIdMap.FleetCarrier] = {
	component: Carrier,
	exact: true,
	id: routesIdMap.FleetCarrier,
	name: 'Carrier',
	path: '/fleet/carrier',
	permissions: [PermissionsType.Fleet],
	icon: 'truck',
	menuText: 'Carrier',
	childrens: [routesIdMap.FleetCarrierNew, routesIdMap.FleetCarrierModify],
};

carrierRoutes[routesIdMap.FleetCarrierNew] = {
	component: CarrierNew,
	exact: true,
	id: routesIdMap.FleetCarrierNew,
	name: 'New carrier',
	path: '/fleet/carrier/new',
	permissions: [PermissionsType.Fleet],
	icon: 'plus',
	menuText: 'New carrier',
	isChild: true,
};

carrierRoutes[routesIdMap.FleetCarrierModify] = {
	component: CarrierModify,
	exact: true,
	id: routesIdMap.FleetCarrierModify,
	name: 'Modify carrier',
	path: '/fleet/carrier/modify/:id',
	permissions: [PermissionsType.Fleet],
	isChild: true,
};
