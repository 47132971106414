import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';
import type { DriverType } from '../types/DriverType';

export interface IAccidentInsertParams extends IServiceBaseParams {
	accidentDateTime?: string;
	bookingNumber?: string;
	branchCode: string;
	circumstances?: string;
	damages?: string;
	driverLicenseNumber?: string;
	driverName?: string;
	driverPhoneNumber?: string;
	driverPhonePrefix?: string;
	driverType?: DriverType;
	photos?: string[];
	place?: string;
	plateNumber: string;
	provider: string;
	sheetFile?: string;
}

export const accidentInsertService = (model: IAccidentInsertParams) => {
	return Api.post<IAccident, IAccidentInsertParams>(AccidentEndpoints.INSERT, model);
};
