/* eslint-disable sort-keys */
export const enum routesIdMap {
	BookingDashboard,
	BookingPickUpSearch,
	BookingReception,
	BookingReceptionMeetingPoint,
	BookingReceptionMeetingPointConfirmed,
	BookingReceptionShuttleBus,
	BookingReceptionShuttleBusConfirmed,
	BookingRegularizations,
	Bookings,
	BookingsDetail,
	BookingsDetailAccident,
	BookingsDetailAccidentDetail,
	BookingsDetailAmounts,
	BookingsDetailAmountsModify,
	BookingsDetailChangeVehicle,
	BookingsDetailChangeVehicleChange,
	BookingsDetailComments,
	BookingsDetailDocuments,
	BookingsDetailExcess,
	BookingsDetailExtend,
	BookingsDetailExtendConfirm,
	BookingsDetailExtraDriverAdd,
	BookingsDetailExtraDriverModify,
	BookingsDetailExtraDrivers,
	BookingsDetailFineDetail,
	BookingsDetailFines,
	BookingsDetailFranchisesDetail,
	BookingsDetailInvoiceTo,
	BookingsDetailInvoiceToCompanyDetail,
	BookingsDetailInvoiceToCompanyNew,
	BookingsDetailInvoiceToCompanySearch,
	BookingsDetailInvoiceToCustomerDetail,
	BookingsDetailInvoiceToCustomerNew,
	BookingsDetailInvoiceToCustomerSearch,
	BookingsDetailRegularizations,
	BookingsDetailRegularizationsDetail,
	BookingsDetailRegularizationsNew,
	BookingsDetailServices,
	BookingsDetailTolls,
	BookingsDropOff,
	BookingsDropOffAmounts,
	BookingsDropOffEnd,
	BookingsDropOffExcess,
	BookingsDropOffServices,
	BookingsDropOffStart,
	BookingsNew,
	BookingsNewAgency,
	BookingsNewAvailability,
	BookingsNewCompany,
	BookingsNewConfirmation,
	BookingsNewCustomer,
	BookingsNewCustomerGeneric,
	BookingsNewCustomerNew,
	BookingsNewCustomerSearch,
	BookingsNewGroups,
	BookingsNewInvoiceTo,
	BookingsNewInvoiceToCompanyNew,
	BookingsNewInvoiceToCompanySearch,
	BookingsNewInvoiceToCustomerNew,
	BookingsNewInvoiceToCustomerSearch,
	BookingsNewServices,
	BookingsNewVendor,
	BookingsPickUp,
	BookingsPickUpCustomer,
	BookingsPickUpCustomerNew,
	BookingsPickUpExtraDrivers,
	BookingsPickUpExtraDriversAdd,
	BookingsPickUpExtraDriversModify,
	BookingsPickUpInvoiceTo,
	BookingsPickUpInvoiceToCompanyDetail,
	BookingsPickUpInvoiceToCompanyNew,
	BookingsPickUpInvoiceToCompanySearch,
	BookingsPickUpInvoiceToCustomerDetail,
	BookingsPickUpInvoiceToCustomerNew,
	BookingsPickUpInvoiceToCustomerSearch,
	BookingsPickUpPayment,
	BookingsPickUpRegister,
	BookingsPickUpServices,
	BookingsPickUpStart,
	BookingsPickUpStartCustomerCompare,
	BookingsPickUpStartCustomerSearchResult,
	BookingsPickUpVehicle,
	BranchSchedule,
	BranchScheduleEdit,
	BranchScheduleNew,
	ChangeBranch,
	ChangePassword,
	Companies,
	CompaniesDetail,
	CompaniesDetailComments,
	CompaniesNew,
	CompanyDashboard,
	ConnectedVehicles,
	ConnectedVehicleTelemetry,
	CracForm,
	CracFormExample,
	CracFormExampleForm,
	CracFormInputField,
	CracFormSelectField,
	CracFormFieldArray,
	CracFormValidations,
	CustomerDashboard,
	Customers,
	CustomersDetail,
	CustomersDetailBookings,
	CustomersDetailComments,
	CustomersDetailCoupons,
	CustomersDetailCouponsDetail,
	CustomersDetailOverviews,
	CustomersDetailPendings,
	CustomersNew,
	CustomersPayments,
	Dashboard,
	DepositRate,
	DepositRateEdit,
	DepositRateNew,
	DiscountCoupon,
	DiscountCouponModify,
	DiscountCouponNew,
	Fleet,
	FleetDocumentation,
	FleetExcessRate,
	FleetExcessRateModify,
	FleetExcessRateNew,
	FleetExcessType,
	FleetExcessTypeModify,
	FleetExcessTypeNew,
	FleetFine,
	FleetFineModify,
	FleetFineNew,
	FleetFineReason,
	FleetFineReasonModify,
	FleetFineReasonNew,
	FleetFineTransmitter,
	FleetFineTransmitterModify,
	FleetFineTransmitterNew,
	FleetInsuranceRate,
	FleetInsuranceRateModify,
	FleetInsuranceRateNew,
	FleetMovementRestriction,
	FleetPreviousRegistration,
	FleetPreviousRegistrationImport,
	FleetPreviousRegistrationModify,
	FleetPreviousRegistrationRegister,
	FleetPrevision,
	FleetPrevisionDetail,
	FleetPrevisionDistribute,
	FleetPrevisionImportPreviousRegistration,
	FleetPrevisionModify,
	FleetPrevisionNew,
	FleetRevisionCost,
	FleetRevisionCostSetBudget,
	FleetRevisionType,
	FleetRevisionTypeModify,
	FleetRevisionTypeNew,
	Sale,
	SaleOrder,
	SaleOrderLineInsert,
	SaleOrderModify,
	SaleOrderNew,
	SaleService,
	SaleServiceNew,
	SaleServiceModify,
	SaleServiceRate,
	SaleServiceRateNew,
	SalePriceModifySearch,
	SalePriceModify,
	FleetTransfer,
	FleetTransferModify,
	FleetTransferModifyAddVehicle,
	FleetTransferNew,
	FleetTransferNewSelected,
	FleetVehicleBrand,
	FleetVehicleBrandModify,
	FleetVehicleBrandNew,
	FleetVehicleColor,
	FleetVehicleColorModify,
	FleetVehicleColorNew,
	FleetVehicleGroup,
	FleetVehicleGroupDetail,
	FleetVehicleGroupModify,
	FleetVehicleGroupNew,
	FleetVehicleModel,
	FleetVehicleModelModify,
	FleetVehicleModelNew,
	FleetVehicleModify,
	FleetVehicleModifyChangeAgreedRepurchaseDate,
	FleetVehicleModifyChangeCarrier,
	FleetVehicleModifyChangeDeFleetDate,
	FleetVehicleModifyChangeFleetTypology,
	FleetVehicleModifyChangeNextITVDate,
	FleetVehicleModifyChangeRegistrationDate,
	FleetVehicleModifyChangeRentDate,
	FleetVehicleModifyChangeRentEndDate,
	FleetVehicleModifyChangeRentStartDate,
	FleetVehicleModifyChangeUnregisterTrafficDate,
	FleetVehicleModifyVehicleGroup,
	FleetVehicleModifyChangeBrandParking,
	FleetVehicleModifyChangeSaleAmount,
	FleetVehicleModifyChangeVehicleModel,
	FleetVehicleModifyChangePlateNumber,
	SaleMargins,
	SaleMarginsNew,
	FleetBrandParkings,
	FleetBrandParkingsNew,
	FleetBrandParkingsModify,
	FleetCarrier,
	FleetCarrierNew,
	FleetCarrierModify,
	FleetMetaModel,
	FleetMetaModelNew,
	FleetMetaModelModify,
	Home,
	IT,
	ITLockers,
	ITLockersNew,
	KmRate,
	KmRateEdit,
	KmRateNew,
	Lockers,
	LockersManagement,
	Management,
	ManagementAdmonBookingLine,
	ManagementCashCurrent,
	ManagementCashCurrentBookings,
	ManagementCashCurrentPayments,
	ManagementCashCurrentSpendings,
	ManagementCashCurrentSpendingsDetail,
	ManagementCashCurrentSpendingsNew,
	ManagementCashDetail,
	ManagementCashDetailBookings,
	ManagementCashDetailPayments,
	ManagementCashDetailPendings,
	ManagementCashDetailSpendings,
	ManagementCashDetailSpendingsDetail,
	ManagementCashHistory,
	ManagementCashPending,
	ManagementComputers,
	ManagementComputersDetail,
	ManagementDashboard,
	ManagementQueues,
	ManagementQueuesDetail,
	ManagementQueuesNew,
	MaximumBookingDuration,
	MaximumBookingDurationNew,
	MaximumBookingDurationNewFrom,
	MaximumExcessRate,
	MaximumExcessRateEdit,
	MaximumExcessRateNew,
	NotAllowedMovement,
	NotAllowedMovementEdit,
	NotAllowedMovementNew,
	OccupancyConfiguration,
	OccupancyConfigurationModify,
	OccupancyConfigurationNew,
	OneWay,
	OneWayEdit,
	OneWayNew,
	PackageConfiguration,
	PackageConfigurationModify,
	PackageConfigurationNew,
	PreauthorizationRate,
	PreauthorizationRateEdit,
	PreauthorizationRateNew,
	PricingChoosableServiceConfiguration,
	PricingChoosableServiceConfigurationModify,
	PricingChoosableServiceConfigurationNew,
	PricingCouponConfiguration,
	PricingCouponConfigurationModify,
	PricingCouponConfigurationNew,
	PricingDashboard,
	PricingIncludedServiceConfiguration,
	PricingIncludedServiceConfigurationModify,
	PricingIncludedServiceConfigurationNew,
	PricingInvoiceableServiceConfiguration,
	PricingInvoiceableServiceConfigurationModify,
	PricingInvoiceableServiceConfigurationNew,
	PricingPartnerCategory,
	PricingPartnerCategoryModify,
	PricingPartnerCategoryNew,
	PricingPartnerGroup,
	PricingPartnerGroupModify,
	PricingPartnerGroupNew,
	RentRateFormulaEdit,
	RentRateFormulaNew,
	RentRateFormulas,
	RentRateMargin,
	RentRateMarginEdit,
	RentRateMarginNew,
	RentRatePriceViewer,
	RentRates,
	RentRateTypeEdit,
	RentRateTypeNew,
	RentRateTypeRelation,
	RentRateTypeRelationModify,
	RentRateTypeRelationNew,
	RentRateTypes,
	RentRateUpdaters,
	RentRateUpdatersEdit,
	RentRateUpdatersFormulas,
	RentRateValidation,
	RentRateValidationModify,
	RentRateValidationNew,
	RentRateVariation,
	RentRateVariationEdit,
	RentRateVariationMargin,
	RentRateVariationMarginEdit,
	RentRateVariationMarginNew,
	RentRateVariationNew,
	ServiceRates,
	ServiceRatesNew,
	ServiceRatesNewFrom,
	ServiceRateTypes,
	ServiceRateTypesEdit,
	ServiceRateTypesNew,
	StopSales,
	StopSalesModify,
	StopSalesNew,
	StopSalesNewFrom,
	VehicleDashboard,
	VehicleGroupAvailability,
	VehicleGroupAvailabilityNew,
	VehicleGroupIncludedConfiguration,
	VehicleGroupIncludedConfigurationModify,
	VehicleGroupIncludedConfigurationNew,
	VehicleGroupOrderAndUpgrade,
	VehicleGroupOrderAndUpgradeNew,
	Vehicles,
	VehiclesDetail,
	VehiclesDetailAccidents,
	VehiclesDetailAccidentsDetail,
	VehiclesDetailBookings,
	VehiclesDetailChangeCarrier,
	VehiclesDetailChangeState,
	VehiclesDetailDamageRepair,
	VehiclesDetailDamages,
	VehiclesDetailDamagesDetail,
	VehiclesDetailDocuments,
	VehiclesDetailDocumentsAdd,
	VehiclesDetailExpedients,
	VehiclesDetailFine,
	VehiclesDetailFineDetail,
	VehiclesDetailHistory,
	VehiclesDetailInsurances,
	VehiclesDetailKeysLocation,
	VehiclesDetailRevisionCost,
	VehiclesDetailTolls,
	VehiclesKeyChainsLabels,
	VehiclesKeyChainsLabelsSelected,
	VehiclesPreparation,
	VehiclesReception,
	VehiclesReceptionDetail,
	VehiclesReturn,
	VehiclesReturnDetail,
	VehiclesRevision,
	Workshop,
	WorkshopAccident,
	WorkshopAccidentModify,
	WorkshopAccidentNew,
	WorkshopAssignment,
	WorkshopAssignmentModify,
	WorkshopAssignmentNew,
	WorkshopDamageRepair,
	WorkshopDamageRepairLink,
	WorkshopExcessRate,
	WorkshopExcessRateModify,
	WorkshopExcessRateNew,
	WorkshopExcessType,
	WorkshopExcessTypeModify,
	WorkshopExcessTypeNew,
	WorkshopRevisionCost,
	WorkshopRevisionCostLink,
	WorkshopRevisionType,
	WorkshopRevisionTypeModify,
	WorkshopRevisionTypeNew,
	WorkshopRevisionChecklist,
	WorkshopSupplier,
	WorkshopSupplierModify,
	WorkshopSupplierNew,
}

export type RouteIdKeyType = keyof typeof routesIdMap;
