import { combineReducers } from '@reduxjs/toolkit';

import { saleMarginsReducer as SaleMargin } from '~/features/sale/saleMargins/state/slice';
import { saleOrderReducer as SaleOrder } from '~/features/sale/saleOrder/state/slice';
import { salePriceModifyReducer as SalePriceModify } from '~/features/sale/salePriceModify/state/slice';
import { saleServiceReducer as SaleService } from '~/features/sale/saleService/state/slice';
import { saleServiceRateReducer as SaleServiceRate } from '~/features/sale/saleServiceRate/state/slice';

export const saleReducer = combineReducers({
	SaleMargin,
	SaleOrder,
	SaleService,
	SaleServiceRate,
	SalePriceModify,
});
