import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderGetByIdParams extends IServiceBaseParams {
	id: number;
}

export const saleOrderGetByIdService = (params: ISaleOrderGetByIdParams) => {
	return Api.get<ISaleOrder, ISaleOrderGetByIdParams>(SaleOrderEndPoints.GET_BY_ID, params);
};
