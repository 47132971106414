import { SaleMarginEndPoints } from './SaleMarginEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleRateSearchParams } from '../../shared/entities/SaleRateBaseEntity';
import type { ISaleMargin } from '../entities/SaleMargin';

export interface ISaleMarginSearchParams extends IServiceBaseParams, ISaleRateSearchParams {
	margin?: number;
}

export const saleMarginSearchService = (model: ISaleMarginSearchParams) => {
	return Api.get<ISaleMargin[], ISaleMarginSearchParams>(SaleMarginEndPoints.SEARCH, model);
};
