import { useEffect, useRef } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { deepEqual } from './helpers';
import type { FormType } from '../form/Form';

/**
 * Hook that triggers validation when the validation prop changes or when validateOnLoad is true.
 * @param param0
 */
export const useValidateField = ({
	validateOnLoad,
	name,
	validation,
}: {
	validateOnLoad?: boolean;
	name: string;
	validation?: any;
}) => {
	const { trigger, triggerListRef } = useFormContext() as FormType<any>;
	const validationRef = useRef(validation);

	useEffect(() => {
		const data = triggerListRef.current;
		const index = data.indexOf(name);

		if (validateOnLoad) {
			validationRef.current = validation;
			if (index === -1) {
				triggerListRef.current.push(name);
			}
		} else if (index !== -1) {
			triggerListRef.current.splice(index, 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Trigger validation when the validation prop changes
		if (!deepEqual(validationRef.current, validation)) {
			validationRef.current = validation;
			trigger(name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, validation]);
};

export const useField = (name?: string, exact?: boolean) => {
	return useWatch({ name: name || '', disabled: !name, exact });
};
