import { createSlice } from '@reduxjs/toolkit';

import {
	salePriceModifyChangePrice,
	salePriceModifyClear,
	salePriceModifySearch,
} from '@crac/core/modules/sale/salePriceModify/state/SalePriceModifyActions';
import type { ISalePriceModifyReducerState } from '@crac/core/modules/sale/salePriceModify/state/SalePriceModifyReducerState';

const initialState: ISalePriceModifyReducerState = {
	salePriceModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	salePriceModifySearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicles: [],
};

const salePriceModifySlice = createSlice({
	name: 'SalePriceModifySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(salePriceModifySearch.pending, (state) => {
			return {
				...state,
				salePriceModifySearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(salePriceModifySearch.rejected, (state, action) => {
			return {
				...state,
				salePriceModifySearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(salePriceModifySearch.fulfilled, (state, action) => {
			return {
				...state,
				salePriceModifySearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicles: action.payload,
			};
		});

		// MODIFY PRICE
		builder
			.addCase(salePriceModifyChangePrice.pending, (state) => {
				return {
					...state,
					salePriceModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(salePriceModifyChangePrice.rejected, (state, action) => {
				return {
					...state,
					salePriceModifyRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(salePriceModifyChangePrice.fulfilled, (state, action) => {
				const plateNumbers = action.payload.map(({ plateNumber }) => plateNumber);

				return {
					...state,
					salePriceModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: [
						...state.vehicles.filter((vehicle) => !plateNumbers.includes(vehicle.plateNumber)),
						...action.payload,
					],
				};
			});

		builder.addCase(salePriceModifyClear, () => {
			return initialState;
		});
	},
});

export const salePriceModifyReducer = salePriceModifySlice.reducer;
