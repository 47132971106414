import type { ElementType } from 'react';
import React from 'react';

import { InputFieldCheckbox } from './InputFieldCheckbox';
import { InputFieldDate } from './InputFieldDate';
import { InputFieldFile } from './InputFieldFile';
import { InputFieldNumber } from './InputFieldNumber';
import { InputFieldRadio } from './InputFieldRadio';
import { InputFieldText } from './InputFieldText';
import { InputFieldTime } from './InputFieldTime';
import type { IInputDateProps, IInputFieldProps, IInputFileProps, IInputNumberProps, IInputTextProps } from './types';
import { FieldGroup } from '../fieldGroup';
import { useValidateField } from '../hooks';

export type InputFieldType<T> = {
	// Trigger validations on load
	validateOnLoad?: boolean;
	// Custom wrapper for component
	wrapper?: ElementType;
} & (IInputFieldProps<T> | IInputFileProps<T> | IInputDateProps<T> | IInputTextProps | IInputNumberProps<T>);

export type InputFieldValueType = string | boolean | number | Date | File[] | null;

export const InputField: React.FC<InputFieldType<InputFieldValueType>> = ({ validateOnLoad, wrapper, ...rest }) => {
	const props = {
		...rest,
		id: rest.id ?? rest.name,
		required: Boolean(rest.validation?.required) || rest.required,
	};

	useValidateField({ ...props, validateOnLoad });

	let element = null;
	const { type } = props;
	switch (type) {
		case 'date':
		case 'datetime-local':
			element = <InputFieldDate {...props} type={type as IInputDateProps<Date>['type']} />;
			break;
		case 'radio':
			props.id = rest.id || `${rest.name}-${rest.value}`;
			element = <InputFieldRadio {...props} />;
			break;
		case 'checkbox':
			element = <InputFieldCheckbox {...props} />;
			break;
		case 'number':
			element = <InputFieldNumber {...props} type={type as IInputNumberProps<number | null>['type']} />;
			break;
		case 'file':
			element = <InputFieldFile {...props} />;
			break;
		case 'time':
			element = <InputFieldTime {...props} />;
			break;
		default:
			element = <InputFieldText {...props} />;
			break;
	}

	return (
		<FieldGroup {...props} wrapper={wrapper}>
			{element}
		</FieldGroup>
	);
};
