import { SaleOrderLineEndPoints } from './SaleOrderLineEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { SaleOrderLineType } from '../../shared/types/SaleOrderLineType';
import type { ISaleOrderLine } from '../entities/SaleOrderLine';

export interface ISaleOrderLineInsertParams extends IServiceBaseParams {
	orderId: number;
	plateNumbers: string[];
	type: SaleOrderLineType;
	/**
	 * Required only for vehicle lines (type = 0)
	 */
	amount?: number;
	/**
	 * Required only for service lines (type = 1)
	 */
	serviceId?: number;
}

/**
 * Inserts a new sale order line
 * @param ISaleOrderLineInsertParams - Sale order line insert parameters
 */
export const saleOrderLineInsertService = (params: ISaleOrderLineInsertParams) => {
	return Api.post<ISaleOrderLine[], ISaleOrderLineInsertParams>(SaleOrderLineEndPoints.INSERT, params);
};
