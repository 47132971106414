import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const FleetVehicleModify = lazyWithRetry(() => import('~/features/fleet/vehicleModify/pages/VehicleModify'));
const FleetVehicleModifyVehicleGroup = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyVehicleGroup'),
);
const FleetVehicleModifyChangeDeFleetDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeDefleetDate'),
);
const FleetVehicleModifyChangeAgreedRepurchaseDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeAgreedRepurchaseDate'),
);
const FleetVehicleModifyChangeNextITVDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeNextITVDate'),
);
const FleetVehicleModifyChangeRentStartDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeRentStartDate'),
);
const FleetVehicleModifyChangeRentEndDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeRentEndDate'),
);
const FleetVehicleModifyChangeUnregisterTrafficDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeUnregisterTrafficDate'),
);
const FleetVehicleModifyChangeFleetTypology = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeFleetTypology'),
);
const FleetVehicleModifyChangeCarrier = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeCarrier'),
);

const FleetVehicleModifyChangeRegistrationDate = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeRegistrationDate'),
);

const FleetVehicleModifyChangeBrandParking = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeBrandParking'),
);

const FleetVehicleModifyChangeSaleAmount = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeSaleAmount'),
);

const FleetVehicleModifyChangeVehicleModel = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangeVehicleModel'),
);

const FleetVehicleModifyChangePlateNumber = lazyWithRetry(
	() => import('~/features/fleet/vehicleModify/pages/VehicleModifyChangePlateNumber'),
);

export const vehicleModifyRoutes: { [key: number]: IRouteConfig } = {};

vehicleModifyRoutes[routesIdMap.FleetVehicleModify] = {
	component: FleetVehicleModify,
	exact: true,
	id: routesIdMap.FleetVehicleModify,
	name: 'Vehicle modify',
	path: '/fleet/vehicle-modify',
	permissions: [PermissionsType.VehicleChangeData],
	icon: 'pencil',
	menuText: 'Modify',
	childrens: [
		routesIdMap.FleetVehicleModifyVehicleGroup,
		routesIdMap.FleetVehicleModifyChangeDeFleetDate,
		routesIdMap.FleetVehicleModifyChangeAgreedRepurchaseDate,
		routesIdMap.FleetVehicleModifyChangeNextITVDate,
		routesIdMap.FleetVehicleModifyChangeRentStartDate,
		routesIdMap.FleetVehicleModifyChangeRentEndDate,
		routesIdMap.FleetVehicleModifyChangeUnregisterTrafficDate,
		routesIdMap.FleetVehicleModifyChangeFleetTypology,
		routesIdMap.FleetVehicleModifyChangeCarrier,
		routesIdMap.FleetVehicleModifyChangeRegistrationDate,
		routesIdMap.FleetVehicleModifyChangeBrandParking,
		routesIdMap.FleetVehicleModifyChangeSaleAmount,
		routesIdMap.FleetVehicleModifyChangeVehicleModel,
		routesIdMap.FleetVehicleModifyChangePlateNumber,
	],
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyVehicleGroup] = {
	component: FleetVehicleModifyVehicleGroup,
	exact: true,
	id: routesIdMap.FleetVehicleModifyVehicleGroup,
	name: 'Change vehicle group',
	path: '/fleet/vehicle-modify/vehicle-group',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeRentStartDate] = {
	component: FleetVehicleModifyChangeRentStartDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeRentStartDate,
	name: 'Change rent start date',
	path: '/fleet/vehicle-modify/rent-start-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeRentEndDate] = {
	component: FleetVehicleModifyChangeRentEndDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeRentEndDate,
	name: 'Change rent end date',
	path: '/fleet/vehicle-modify/rent-end-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeUnregisterTrafficDate] = {
	component: FleetVehicleModifyChangeUnregisterTrafficDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeUnregisterTrafficDate,
	name: 'Change unregister traffic date',
	path: '/fleet/vehicle-modify/unregister-traffic-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeDeFleetDate] = {
	component: FleetVehicleModifyChangeDeFleetDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeDeFleetDate,
	name: 'Change defleet date',
	path: '/fleet/vehicle-modify/defleet-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeAgreedRepurchaseDate] = {
	component: FleetVehicleModifyChangeAgreedRepurchaseDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeAgreedRepurchaseDate,
	name: 'Change agreed repurchase date',
	path: '/fleet/vehicle-modify/agreed-repurchase-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeNextITVDate] = {
	component: FleetVehicleModifyChangeNextITVDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeNextITVDate,
	name: 'Change next ITV date',
	path: '/fleet/vehicle-modify/next-itv-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeDeFleetDate] = {
	component: FleetVehicleModifyChangeDeFleetDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeDeFleetDate,
	name: 'Change defleet date',
	path: '/fleet/vehicle-modify/defleet-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeAgreedRepurchaseDate] = {
	component: FleetVehicleModifyChangeAgreedRepurchaseDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeAgreedRepurchaseDate,
	name: 'Change agreed repurchase date',
	path: '/fleet/vehicle-modify/agreed-repurchase-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeNextITVDate] = {
	component: FleetVehicleModifyChangeNextITVDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeNextITVDate,
	name: 'Change next ITV date',
	path: '/fleet/vehicle-modify/next-itv-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeFleetTypology] = {
	component: FleetVehicleModifyChangeFleetTypology,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeFleetTypology,
	name: 'Change fleet typology',
	path: '/fleet/vehicle-modify/fleet-typology',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeCarrier] = {
	component: FleetVehicleModifyChangeCarrier,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeCarrier,
	name: 'Change carrier',
	path: '/fleet/vehicle-modify/change-carrier',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'truck',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeRegistrationDate] = {
	component: FleetVehicleModifyChangeRegistrationDate,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeRegistrationDate,
	name: 'Change registration date',
	path: '/fleet/vehicle-modify/change-registration-date',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'calendar',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeBrandParking] = {
	component: FleetVehicleModifyChangeBrandParking,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeBrandParking,
	name: 'Change brand parking',
	path: '/fleet/vehicle-modify/change-brand-parking',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'product-hunt',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeSaleAmount] = {
	component: FleetVehicleModifyChangeSaleAmount,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeSaleAmount,
	name: 'Change sale amount',
	path: '/fleet/vehicle-modify/change-sale-amount',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'bar-chart',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangeVehicleModel] = {
	component: FleetVehicleModifyChangeVehicleModel,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangeVehicleModel,
	name: 'Change vehicle model',
	path: '/fleet/vehicle-modify/change-vehicle-model',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'car',
};

vehicleModifyRoutes[routesIdMap.FleetVehicleModifyChangePlateNumber] = {
	component: FleetVehicleModifyChangePlateNumber,
	exact: true,
	id: routesIdMap.FleetVehicleModifyChangePlateNumber,
	name: 'Change plate number',
	path: '/fleet/vehicle-modify/change-plate-number',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'bar-chart',
};
