import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';

export interface IAccidentGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

export const accidentGetByBookingService = (model: IAccidentGetByBookingParams) => {
	return Api.get<IAccident[], IAccidentGetByBookingParams>(AccidentEndpoints.GET_BY_BOOKING, model);
};
