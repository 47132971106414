import React from 'react';

import classNames from 'classnames';
import type { ControllerProps } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';

import { useInputFieldTimeProps } from './hooks';
import type { IInputFieldProps } from './types';

export const InputFieldTime: React.FC<IInputFieldProps<string>> = ({
	component: Component = 'input',
	innerRef,
	...rest
}) => {
	/* Hooks */
	const { onChange, onBlur, validation, ...props } = useInputFieldTimeProps(rest);
	const { name } = props;

	const { control, formState, getFieldState } = useFormContext();

	const { field } = useController({
		name,
		control,
		rules: validation as ControllerProps['rules'],
	});

	/* Handlers */
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		field.onChange(event);
		if (onChange) {
			onChange(value);
		}
	};

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		field.onBlur();
		if (onBlur) {
			onBlur(event);
		}
	};

	/* Constants */
	const { error } = getFieldState(name, formState);
	const componentClassName = classNames('form-control', {
		'is-invalid': Boolean(error),
	});

	const value = field.value ?? '';

	const handleRef = (ref: HTMLInputElement | null) => {
		field.ref(ref);
		if (innerRef) {
			innerRef.current = ref;
		}
	};

	return (
		<Component
			{...props}
			className={componentClassName}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			ref={handleRef}
			value={value}
		/>
	);
};
