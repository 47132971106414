import { round, sumAmounts } from '../../helpers/numbers';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IBookingPackage, IBookingPackageService } from '../../models/entities/BookingPackage';
import type { IDiscount } from '../../models/entities/Discount';
import type { IProvider } from '../../models/entities/Provider';
import type { IService, IServiceFeatures } from '../../models/entities/Service';
import { BookingPackageType } from '../../models/types/BookingPackageType';
import type { IBookingAmount } from '../types/IBookingAllAmount';

/**
 * Check if group have packages
 */
export const canShowPackages = (groups: IVehicleGroupAvailabilityAndPrice[]): boolean => {
	return (
		groups.filter(
			(group) => group.packages.length > 1 && group.available && group.permittedTransfer && !group.noRates,
		).length > 0
	);
};

/**
 * Return package from vehicle group or null if not exist
 */
export const getPackageFromGroup = (
	group: IVehicleGroupAvailabilityAndPrice,
	packageCode: string,
): IBookingPackage | null => {
	if (group.packages.length > 0) {
		const currentPackage = group.packages.find((pack) => pack.code === packageCode);
		if (currentPackage) {
			return currentPackage;
		}
	}

	return null;
};

/**
 * Return packages configured on group or []
 */
export const getPackagesFromGroup = (group: IVehicleGroupAvailabilityAndPrice): IBookingPackage[] => {
	if (group.packages.length > 0) {
		return group.packages;
	}

	return [];
};

/**
 * Suma el importe del paquete con el importe de los extras obligatorios
 */
export const getPackageFinalAmount = (pack: IBookingPackage, discountAmount: number): IBookingAmount => {
	return {
		net: pack.netAmount,
		retail: round(pack.retailAmount - discountAmount, 2),
	};
};

/**
 * Return minimum package amount
 */
export const getMinPackageAmount = (group: IVehicleGroupAvailabilityAndPrice): number => {
	const { packages } = group;
	const packageAmounts = packages.map((pack) => pack.amount);
	return Math.min(...packageAmounts);
};

/**
 * Map good and bad features from package
 * @param currentPackage Current package
 * @param services Vehicle group services
 * @returns Good and bad features (IServiceFeatures[])
 */
type PackageFeatureType = {
	goodFeatures?: IServiceFeatures[];
	badFeatures?: IServiceFeatures[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFeaturesFromServices = (services: IService[], upperServices: IService[] = []): PackageFeatureType => {
	const goodFeatures: IServiceFeatures[] = [];
	const badFeatures: IServiceFeatures[] = [];

	services.forEach((service) => {
		if (service.includes && service.includes.length > 0) {
			goodFeatures.push(...service.includes.map((serv) => serv.goodFeatures).flat());
		} else if (service.goodFeatures) {
			if (service.goodFeatures) {
				goodFeatures.push(...service.goodFeatures);
			}

			if (service.badFeatures) {
				// BadFeatures.push(...service.badFeatures);
			}
		}
	});

	if (upperServices.length > 0) {
		upperServices.forEach((service) => {
			const currentPackageService = services.find((serv) => serv.code === service.code);
			if (!currentPackageService) {
				if (service.includes && service.includes.length > 0) {
					badFeatures.push(...service.includes.map((serv) => serv.badFeatures).flat());
				} else if (service.badFeatures) {
					badFeatures.push(...service.badFeatures);
				}
			}
		});
	}

	return {
		badFeatures,
		goodFeatures,
	};
};

const getCompoundServicesFromPackageService = (service: IService, groupServices: IService[]): IService[] => {
	if (!service) {
		return [];
	}
	return service.includes?.length
		? service.includes?.map(
				(includedService) =>
					groupServices.find((groupService) => groupService.code === includedService.code) as IService,
			)
		: groupServices.filter((groupService) => groupService.code === service.code);
};

const getCompoundServicesFromPackage = (groupPackage: IBookingPackage, groupServices: IService[]): IService[] =>
	groupPackage.services.reduce(
		(packageServices: IService[], servicePackage: IBookingPackageService): IService[] => [
			...packageServices,
			...getCompoundServicesFromPackageService(
				groupServices.find((groupService) => groupService.code === servicePackage.code) as IService,
				groupServices,
			),
		],
		[],
	);

const addFeaturesToPackages = (groupPackages: IBookingPackage[], groupServices: IService[]) =>
	groupPackages.map((groupPackage) => {
		const otherGroupPackages = groupPackages.filter(
			(otherGroupPackage) => otherGroupPackage.code !== groupPackage.code,
		);
		const services = getCompoundServicesFromPackage(groupPackage, groupServices);

		const [currentService] = services;
		if (currentService && currentService.goodFeatures) {
			groupPackage.goodFeatures = services
				.sort((serviceA, serviceB) => serviceA.order - serviceB.order)
				.reduce(
					(goodFeatures: IServiceFeatures[], service: IService): IServiceFeatures[] => [
						...goodFeatures,
						...(service?.goodFeatures as IServiceFeatures[]),
					],
					[],
				);

			groupPackage.badFeatures = [
				...new Set(
					otherGroupPackages.reduce(
						(features: IServiceFeatures[], otherGroupPackage: IBookingPackage): IServiceFeatures[] => [
							...features,
							...getCompoundServicesFromPackage(otherGroupPackage, groupServices)
								.filter(
									(packageService) =>
										!services.some((service) => service.code === packageService.code),
								)
								.reduce(
									(packageFeatures: IServiceFeatures[], service: IService): IServiceFeatures[] => [
										...packageFeatures,
										...(service?.badFeatures as IServiceFeatures[]),
									],
									[],
								),
						],
						[],
					),
				),
			];
		}

		return groupPackage;
	});

/**
 * Map package features
 * @param packages package list
 * @param groupServices vehicle group services
 * @returns packageList IBookingPackage[]
 */
const mapVehicleGroupPackages = (packages: IBookingPackage[], groupServices: IService[]): IBookingPackage[] => {
	if (packages.length > 0) {
		const packagesWithFeatures = addFeaturesToPackages(packages, groupServices);
		const packagesWithContent = [];

		const premiumPackage = packagesWithFeatures.find(
			(groupPackage) => groupPackage.code === BookingPackageType.Premium,
		) as IBookingPackage;
		const comfortPackage = packagesWithFeatures.find(
			(groupPackage) => groupPackage.code === BookingPackageType.Comfort,
		) as IBookingPackage;
		const basicPackage = packagesWithFeatures.find(
			(groupPackage) => groupPackage.code === BookingPackageType.Basic,
		) as IBookingPackage;

		if (premiumPackage) {
			packagesWithContent.push({ ...premiumPackage, recommended: true });
		}

		if (comfortPackage) {
			packagesWithContent.push(comfortPackage);
		}

		if (basicPackage) {
			packagesWithContent.push(basicPackage);
		}

		return packagesWithContent;
	}
	return packages;
};

export const mapPackageServices = (
	groups: IVehicleGroupAvailabilityAndPrice[],
): IVehicleGroupAvailabilityAndPrice[] => {
	if (groups.length > 0) {
		const listGroups: IVehicleGroupAvailabilityAndPrice[] = groups.map(({ services, packages, ...rest }) => ({
			...rest,
			packages: mapVehicleGroupPackages(packages, services),
			services,
		}));

		return listGroups;
	}

	return groups;
};

/**
 * Cualcula el descuento de paquete
 * - Si no hay descuent en el grupo de vehículo, calculamos un descuento fijo para el paquete premium
 * - Si no hay descuento y no es paquete premium no retornamos nada
 */
export const getPackageAmountsWithDiscount = (params: {
	services: IService[];
	bookingPackage: IBookingPackage;
	agencyCode?: string;
	days: number;
	discount?: IDiscount | null;
	provider?: IProvider;
}) => {
	const { bookingPackage, services, discount, days } = params;
	const hasPackageDiscount = discount && discount?.percentage > 0 && discount.amount > 0;
	const isPremiumPackage = bookingPackage.code === BookingPackageType.Premium;

	const packageAmount = round(
		sumAmounts(
			services.filter((service) => service.minimumQuantity >= 1),
			'retailAmount',
		) + bookingPackage.retailAmount,
	);

	// Has vehicle group discount
	if (hasPackageDiscount && !isPremiumPackage) {
		return {
			discountPercentage: discount.percentage,
			newAmount: round(packageAmount - discount.amount),
			oldAmount: packageAmount,
		};
	}

	// If is premium and no has vehicle group discount
	if (isPremiumPackage) {
		const oldAmount = packageAmount + 6 * days;
		let newAmount = packageAmount;

		if (hasPackageDiscount) {
			newAmount = round(packageAmount - discount.amount);
		}

		const discountPercentage = round(100 - (newAmount * 100) / oldAmount, 0);

		return {
			discountPercentage,
			newAmount,
			oldAmount,
		};
	}

	return undefined;
};
