import type { INavItem } from '@crac/components/intranet/shared/layout/sidebar/types';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';
import { checkIfHasPermissions } from '~/features/shared/helpers/checkIfHasPermissions';

const navigationItems: INavItem[] = [
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.BookingDashboard].icon as string}`,
		name: routes[routesIdMap.BookingDashboard].menuText as string,
		roles: routes[routesIdMap.BookingDashboard].permissions,
		children: [
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Bookings].icon as string}`,
				name: routes[routesIdMap.Bookings].menuText as string,
				roles: routes[routesIdMap.Bookings].permissions,
				url: routes[routesIdMap.Bookings].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.BookingsNew].icon as string}`,
				name: routes[routesIdMap.BookingsNew].menuText as string,
				roles: routes[routesIdMap.BookingsNew].permissions,
				url: routes[routesIdMap.BookingsNew].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.BookingsPickUp].icon as string}`,
				name: routes[routesIdMap.BookingsPickUp].menuText as string,
				roles: routes[routesIdMap.BookingsPickUp].permissions,
				url: routes[routesIdMap.BookingsPickUp].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.BookingsDropOff].icon as string}`,
				name: routes[routesIdMap.BookingsDropOff].menuText as string,
				roles: routes[routesIdMap.BookingsDropOff].permissions,
				url: routes[routesIdMap.BookingsDropOff].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.BookingReception].icon as string}`,
				name: routes[routesIdMap.BookingReception].menuText as string,
				roles: routes[routesIdMap.BookingReception].permissions,
				url: routes[routesIdMap.BookingReception].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.BookingRegularizations].icon as string}`,
				name: routes[routesIdMap.BookingRegularizations].menuText as string,
				roles: routes[routesIdMap.BookingRegularizations].permissions,
				url: routes[routesIdMap.BookingRegularizations].path,
			},
		],
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.VehicleDashboard].icon as string}`,
		name: routes[routesIdMap.VehicleDashboard].menuText as string,
		roles: routes[routesIdMap.VehicleDashboard].permissions,
		children: [
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Vehicles].icon as string}`,
				name: routes[routesIdMap.Vehicles].menuText as string,
				roles: routes[routesIdMap.Vehicles].permissions,
				url: routes[routesIdMap.Vehicles].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.VehiclesReception].icon as string}`,
				name: routes[routesIdMap.VehiclesReception].menuText as string,
				roles: routes[routesIdMap.VehiclesReception].permissions,
				url: routes[routesIdMap.VehiclesReception].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.VehiclesReturn].icon as string}`,
				name: routes[routesIdMap.VehiclesReturn].menuText as string,
				roles: routes[routesIdMap.VehiclesReturn].permissions,
				url: routes[routesIdMap.VehiclesReturn].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.VehiclesKeyChainsLabels].icon as string}`,
				name: routes[routesIdMap.VehiclesKeyChainsLabels].menuText as string,
				roles: routes[routesIdMap.VehiclesKeyChainsLabels].permissions,
				url: routes[routesIdMap.VehiclesKeyChainsLabels].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.VehiclesPreparation].icon as string}`,
				name: routes[routesIdMap.VehiclesPreparation].menuText as string,
				roles: routes[routesIdMap.VehiclesPreparation].permissions,
				url: routes[routesIdMap.VehiclesPreparation].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Lockers].icon as string}`,
				name: routes[routesIdMap.Lockers].menuText as string,
				roles: routes[routesIdMap.Lockers].permissions,
				url: routes[routesIdMap.Lockers].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.VehiclesRevision].icon as string}`,
				name: routes[routesIdMap.VehiclesRevision].menuText as string,
				roles: routes[routesIdMap.VehiclesRevision].permissions,
				url: routes[routesIdMap.VehiclesRevision].path,
			},
		],
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Fleet].icon as string}`,
		name: routes[routesIdMap.Fleet].menuText as string,
		roles: routes[routesIdMap.Fleet].permissions,
		url: routes[routesIdMap.Fleet].path,
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Workshop].icon as string}`,
		name: routes[routesIdMap.Workshop].menuText as string,
		roles: routes[routesIdMap.Workshop].permissions,
		url: routes[routesIdMap.Workshop].path,
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Sale].icon as string}`,
		name: routes[routesIdMap.Sale].menuText as string,
		roles: routes[routesIdMap.Sale].permissions,
		url: routes[routesIdMap.Sale].path,
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.PricingDashboard].icon as string}`,
		name: routes[routesIdMap.PricingDashboard].menuText as string,
		roles: routes[routesIdMap.PricingDashboard].permissions,
		url: routes[routesIdMap.PricingDashboard].path,
	},

	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.CustomerDashboard].icon as string}`,
		name: routes[routesIdMap.CustomerDashboard].menuText as string,
		roles: routes[routesIdMap.CustomerDashboard].permissions,
		children: [
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Customers].icon as string}`,
				name: routes[routesIdMap.Customers].menuText as string,
				roles: routes[routesIdMap.Customers].permissions,
				url: routes[routesIdMap.Customers].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.CustomersNew].icon as string}`,
				name: routes[routesIdMap.CustomersNew].menuText as string,
				roles: routes[routesIdMap.CustomersNew].permissions,
				url: routes[routesIdMap.CustomersNew].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.CustomersPayments].icon as string}`,
				name: routes[routesIdMap.CustomersPayments].menuText as string,
				roles: routes[routesIdMap.CustomersPayments].permissions,
				url: routes[routesIdMap.CustomersPayments].path,
			},
		],
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.CompanyDashboard].icon as string}`,
		name: routes[routesIdMap.CompanyDashboard].menuText as string,
		roles: routes[routesIdMap.CompanyDashboard].permissions,
		children: [
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.Companies].icon as string}`,
				name: routes[routesIdMap.Companies].menuText as string,
				roles: routes[routesIdMap.Companies].permissions,
				url: routes[routesIdMap.Companies].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.CompaniesNew].icon as string}`,
				name: routes[routesIdMap.CompaniesNew].menuText as string,
				roles: routes[routesIdMap.CompaniesNew].permissions,
				url: routes[routesIdMap.CompaniesNew].path,
			},
		],
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.ManagementDashboard].icon as string}`,
		name: routes[routesIdMap.ManagementDashboard].menuText as string,
		roles: routes[routesIdMap.ManagementCashCurrent].permissions,
		children: [
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.ManagementCashCurrent].icon as string}`,
				name: routes[routesIdMap.ManagementCashCurrent].menuText as string,
				roles: routes[routesIdMap.ManagementCashCurrent].permissions,
				url: routes[routesIdMap.ManagementCashCurrent].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.ManagementComputers].icon as string}`,
				name: routes[routesIdMap.ManagementComputers].menuText as string,
				roles: routes[routesIdMap.ManagementComputers].permissions,
				url: routes[routesIdMap.ManagementComputers].path,
			},
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.ManagementQueues].icon as string}`,
				name: routes[routesIdMap.ManagementQueues].menuText as string,
				roles: routes[routesIdMap.ManagementQueues].permissions,
				url: routes[routesIdMap.ManagementQueues].path,
			},
		],
	},
	{
		icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.IT].icon as string}`,
		name: routes[routesIdMap.IT].menuText as string,
		roles: routes[routesIdMap.IT].permissions,
		children: [
			{
				icon: `nav-icon fa fa-fw fa-${routes[routesIdMap.ITLockers].icon as string}`,
				name: routes[routesIdMap.ITLockers].menuText as string,
				roles: routes[routesIdMap.ITLockers].permissions,
				url: routes[routesIdMap.ITLockers].path,
			},
		],
	},
];

/**
 * @returns Menu items depending on employee permissions
 */
export const getNavigationItems = (employeePermissions: string[]) => {
	const menuItems: INavItem[] = [];
	navigationItems.forEach((item) => {
		const hasPermission = checkIfHasPermissions(item.roles, employeePermissions);
		if (hasPermission) {
			const newItem: INavItem = {
				icon: item.icon,
				name: item.name,
				roles: item.roles,
			};

			if (item.children) {
				item.children.forEach((child) => {
					const childHasPermission = checkIfHasPermissions(child.roles, employeePermissions);

					if (childHasPermission) {
						newItem.children ||= [];
						newItem.children.push({ ...child });
					}
				});
			} else {
				newItem.url = item.url ? item.url.slice() : item.url;
			}
			menuItems.push(newItem);
		}
	});

	return menuItems;
};
