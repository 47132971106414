import type { ILink } from '../../../../models/entities/Link';

export enum AccidentDocumentType {
	Sheet,
	AppraisalPhoto,
	AppraisalReceived,
	AppraisalCollected,
	Declaration,
	DeclarationReceived,
	/**
	 * Aquí están los documentos que se guardaron con el antiguo flujo de accidente
	 */
	Other,
}

export type AccidentDocument = {
	accidentDocumentType: AccidentDocumentType;
} & ILink;
