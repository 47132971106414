import { brandParkingsRoutes } from './brandParkings/routes';
import { carrierRoutes } from './carrier/routes';
import { connectedVehiclesRoutes } from './connectedVehicles/routes';
import { dashboardRoutes } from './dashboard/routes';
import { documentationRoutes } from './documentation/routes';
import { finesRoutes } from './fine/routes';
import { fineReasonRoutes } from './fineReason/routes';
import { fineTransmitterRoutes } from './fineTransmitter/routes';
import { insuranceRateRoutes } from './insuranceRate/routes';
import { metaModelRoutes } from './metaModel/routes';
import { movementRestrictionRoutes } from './movementRestriction/routes';
import { previousRegistrationRoutes } from './previousRegistration/routes';
import { previsionRoutes } from './prevision/routes';
import { transferRoutes } from './transfer/routes';
import { vehicleBrandRoutes } from './vehicleBrand/routes';
import { vehicleColorRoutes } from './vehicleColor/routes';
import { vehicleGroupRoutes } from './vehicleGroup/routes';
import { vehicleModelRoutes } from './vehicleModel/routes';
import { vehicleModifyRoutes } from './vehicleModify/routes';
import { saleMarginsRoutes } from '../sale/saleMargins/routes';
import { saleOrderRoutes } from '../sale/saleOrder/routes';
import { saleServiceRoutes } from '../sale/saleService/routes';
import { saleServiceRateRoutes } from '../sale/saleServiceRate/routes';

export const fleetRoutes = {
	...brandParkingsRoutes,
	...carrierRoutes,
	...connectedVehiclesRoutes,
	...dashboardRoutes,
	...documentationRoutes,
	...fineReasonRoutes,
	...finesRoutes,
	...fineTransmitterRoutes,
	...insuranceRateRoutes,
	...metaModelRoutes,
	...movementRestrictionRoutes,
	...previousRegistrationRoutes,
	...previsionRoutes,
	...saleMarginsRoutes,
	...saleOrderRoutes,
	...saleServiceRateRoutes,
	...saleServiceRoutes,
	...transferRoutes,
	...vehicleBrandRoutes,
	...vehicleColorRoutes,
	...vehicleGroupRoutes,
	...vehicleModelRoutes,
	...vehicleModifyRoutes,
};
