import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';
import type { AccidentStateType } from '../types/AccidentStateType';
import type { ResponsibilityType } from '../types/ResponsibilityType';

export interface IAccidentSearchParams extends IServiceBaseParams {
	accidentStates?: AccidentStateType[];
	bookingNumber?: string;
	branchCode?: string;
	driverName?: string;
	dateFrom?: string;
	dateTo?: string;
	documentNumber?: string;
	insuranceReference?: string;
	lastCheckFrom?: string;
	lastCheckTo?: string;
	modifiedFrom?: string;
	modifiedTo?: string;
	amountFrom?: number;
	amountTo?: number;
	amountCollectedFrom?: number;
	amountCollectedTo?: number;
	plateNumber?: string;
	provider: string;
	responsibilities?: ResponsibilityType[];
}

export const accidentSearchService = (model: IAccidentSearchParams) => {
	return Api.get<IAccident[], IAccidentSearchParams>(AccidentEndpoints.SEARCH, model);
};
