import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	lockerCellClearSearch,
	lockerCellGetByBranch,
	lockerCellGetEmptyByBranch,
	lockerCellGetReturnKeys,
	lockerCellInsert,
	lockerCellLinesGetByLockerCell,
	lockerCellLinesGetByPlateNumber,
	lockerCellLinesUpdate,
	lockerCellOpen,
	lockerStatusUpdate,
} from '@crac/core/redux/actions/LockerActions';
import type { ILockerCellReducerState } from '@crac/core/redux-store/reducersState/locker';
const initialState: ILockerCellReducerState = {
	lockerCell: null,
	lockerCellCancelRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellCloseRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellGetByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellGetEmptyByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellGetReturnKeysRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellLines: [],
	lockerCellLinesGetByCellRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellLinesGetByPlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellOpenRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCellInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lockerCells: [],
	lockerCellsReturnKey: [],
};

const lockerSlice = createSlice({
	name: 'LockerSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BRANCH
		builder
			.addCase(lockerCellGetByBranch.pending, (state) => {
				return {
					...state,
					lockerCellGetByBranchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellGetByBranch.fulfilled, (state, action) => {
				return {
					...state,
					lockerCellGetByBranchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					lockerCells: deepCopy(action.payload),
				};
			})
			.addCase(lockerCellGetByBranch.rejected, (state, action) => {
				return {
					...state,
					lockerCellGetByBranchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR LOCKER SEARCH
		builder.addCase(lockerCellClearSearch, (state) => {
			return {
				...state,
				lockerCells: [],
			};
		});
		// GET EMPTY BY BRANCH
		builder
			.addCase(lockerCellGetEmptyByBranch.pending, (state) => {
				return {
					...state,
					lockerCellGetEmptyByBranchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellGetEmptyByBranch.fulfilled, (state, action) => {
				return {
					...state,
					lockerCell: deepCopy(action.payload),
					lockerCellGetEmptyByBranchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellGetEmptyByBranch.rejected, (state, action) => {
				return {
					...state,
					lockerCellGetEmptyByBranchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// RETURN KEYS
		builder
			.addCase(lockerCellGetReturnKeys.pending, (state) => {
				return {
					...state,
					lockerCellGetReturnKeysRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellGetReturnKeys.fulfilled, (state, action) => {
				return {
					...state,
					lockerCellGetReturnKeysRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					lockerCellsReturnKey: deepCopy(action.payload),
				};
			})
			.addCase(lockerCellGetReturnKeys.rejected, (state, action) => {
				return {
					...state,
					lockerCellGetReturnKeysRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// LINES_GET_BY_LOCKER_CELL
		builder
			.addCase(lockerCellLinesGetByLockerCell.pending, (state) => {
				return {
					...state,
					lockerCellLinesGetByCellRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellLinesGetByLockerCell.fulfilled, (state, action) => {
				return {
					...state,
					lockerCellLines: deepCopy(action.payload),
					lockerCellLinesGetByCellRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellLinesGetByLockerCell.rejected, (state, action) => {
				return {
					...state,
					lockerCellLinesGetByCellRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// LOCKER CELL LINES UPDATE
		builder.addCase(lockerCellLinesUpdate, (state, action) => {
			return {
				...state,
				lockerCellsReturnKey: deepCopy(action.payload),
			};
		});
		// GET LINES BY PLATE NUMBER
		builder
			.addCase(lockerCellLinesGetByPlateNumber.pending, (state) => {
				return {
					...state,
					lockerCellLinesGetByPlateNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellLinesGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					lockerCellLines: deepCopy(action.payload),
					lockerCellLinesGetByPlateNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellLinesGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					lockerCellLinesGetByPlateNumberRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// OPEN
		builder
			.addCase(lockerCellOpen.pending, (state) => {
				return {
					...state,
					lockerCellOpenRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellOpen.fulfilled, (state, action) => {
				return {
					...state,
					lockerCell: deepCopy(action.payload),
					lockerCellOpenRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					lockerCells: [...state.lockerCells.filter((cell) => cell.id !== action.payload.id), action.payload],
				};
			})
			.addCase(lockerCellOpen.rejected, (state, action) => {
				return {
					...state,
					lockerCellOpenRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE LOCKER CELL LIST
		builder.addCase(lockerStatusUpdate, (state, action) => {
			return {
				...state,
				lockerCells: deepCopy(action.payload),
			};
		});
		// INSERT LOCKER CELL
		builder
			.addCase(lockerCellInsert.pending, (state) => {
				return {
					...state,
					lockerCellInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellInsert.fulfilled, (state, action) => {
				return {
					...state,
					lockerCell: deepCopy(action.payload),
					lockerCellInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(lockerCellInsert.rejected, (state, action) => {
				return {
					...state,
					lockerCellInsertRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const lockerReducer = lockerSlice.reducer;
