import type { FC } from 'react';
import React from 'react';

import type { OptionProps } from 'react-select';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';

import type { ISelectOption } from './types';

interface IOptionStyleProps {
	selected?: boolean;
	disabled?: boolean;
}

const OptionStyle = styled.div<IOptionStyleProps>`
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	border-bottom: 1px solid #ebeffd;
	:last-child {
		border-bottom: none;
	}

	.extra-options {
		display: flex;
		gap: 1rem;
	}

	background-color: ${({ selected }) => (selected ? '#D6E4FF' : 'transparent')};

	&:hover {
		background-color: #ebeffd;
	}
`;

export type ICustomSelectOptionProps<T> = {
	renderOption: FC<T>;
} & OptionProps<ISelectOption<T>, boolean, { label: string; options: ISelectOption<T>[] }>;

export const CustomSelectOption = ({ data, innerProps, children, renderOption }: ICustomSelectOptionProps<unknown>) => {
	return (
		<OptionStyle
			className="px-3 py-2 select-custom-option"
			disabled={data.isDisabled}
			selected={Boolean(innerProps['aria-selected'])}
		>
			<div {...innerProps}>
				<p className="m-0">{children}</p>
				{renderOption ? renderOption({ ...data }) : null}
			</div>
		</OptionStyle>
	);
};
