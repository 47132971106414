import { createSlice } from '@reduxjs/toolkit';

import type { ISaleOrder } from '@crac/core/modules/sale/saleOrder/entities/SaleOrder';
import {
	saleOrderClear,
	saleOrderGetById,
	saleOrderInsert,
	saleOrderLineDelete,
	saleOrderLineGetByOrderId,
	saleOrderLineInsert,
	saleOrderSearch,
} from '@crac/core/modules/sale/saleOrder/state/SaleOrderActions';
import type { ISaleOrderReducerState } from '@crac/core/modules/sale/saleOrder/state/SaleOrderReducerState';

const initialState: ISaleOrderReducerState = {
	saleOrderInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
		data: null,
	},
	saleOrders: [],
	saleOrderSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrder: null,
	saleOrderGetByIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderLines: null,
	saleOrderLineGetByOrderIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderLineInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderLineDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const saleOrderSlice = createSlice({
	name: 'SaleOrderSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY ID
		builder
			.addCase(saleOrderGetById.pending, (state) => {
				return {
					...state,
					saleOrderGetByIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderGetById.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrderGetByIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderGetById.rejected, (state, action) => {
				return {
					...state,
					saleOrderGetByIdRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(saleOrderSearch.pending, (state) => {
				return {
					...state,
					saleOrderSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderSearch.fulfilled, (state, action) => {
				return {
					...state,
					saleOrders: action.payload,
					saleOrderSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderSearch.rejected, (state, action) => {
				return {
					...state,
					saleOrderSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(saleOrderInsert.pending, (state) => {
				return {
					...state,
					saleOrderInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
						data: null,
					},
				};
			})
			.addCase(saleOrderInsert.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
						data: action.payload as ISaleOrder,
					},
				};
			})
			.addCase(saleOrderInsert.rejected, (state, action) => {
				return {
					...state,
					saleOrderInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
						data: null,
					},
				};
			});

		// CLEAR
		builder.addCase(saleOrderClear, () => {
			return initialState;
		});

		// ORDER LINES GET BY ORDER ID
		builder
			.addCase(saleOrderLineGetByOrderId.pending, (state) => {
				return {
					...state,
					saleOrderLineGetByOrderIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineGetByOrderId.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderLines: action.payload,
					saleOrderLineGetByOrderIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineGetByOrderId.rejected, (state, action) => {
				return {
					...state,
					saleOrderLineGetByOrderIdRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// LINES INSERT
		builder
			.addCase(saleOrderLineInsert.pending, (state) => {
				return {
					...state,
					saleOrderLineInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineInsert.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderLineInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					saleOrderLines: state.saleOrderLines
						? [...state.saleOrderLines, ...action.payload]
						: action.payload,
				};
			})
			.addCase(saleOrderLineInsert.rejected, (state, action) => {
				return {
					...state,
					saleOrderLineInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(saleOrderLineDelete.pending, (state) => {
				return {
					...state,
					saleOrderLineDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineDelete.fulfilled, (state) => {
				return {
					...state,
					saleOrderLineDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineDelete.rejected, (state, action) => {
				return {
					...state,
					saleOrderLineDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const saleOrderReducer = saleOrderSlice.reducer;
