import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';

export interface IAccidentModifyParams extends IServiceBaseParams, Omit<IAccident, 'accidentState'> {
	provider: string;
	appraisalAmountCollectedUpload?: File[];
	appraisalReceivedUpload?: File[];
	appraisalPhotosUpload?: File[];
	dneaUpload?: File[];
	dneaUploadReceivedAt?: File[];
	sheetUpload?: File[];
}
export const accidentModifyService = (model: IAccidentModifyParams) => {
	return Api.post<IAccident, IAccidentModifyParams>(AccidentEndpoints.MODIFY, model);
};
