import { DateTime } from 'luxon';

import type { IInputFieldProps } from './types';

/**
 * Formats a date based on the input type ('date' or 'datetime-local').
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
 *
 * @param date - The date to be formatted. Can be a string, Date object, or null.
 * @param type - The type of HTML input ('date' or 'datetime-local'). Default is 'date'.
 * @param formatMap - The format map for the date types. Default is { date: 'yyyy-MM-dd', 'datetime-local': 'yyyy-MM-ddTHH:mm' }.
 * @returns The formatted date string, or null if the input date is null.
 */
export const formatDateByType = (
	date: string | Date | null,
	type: 'date' | 'datetime-local',
	formatMap: {
		[key in 'date' | 'datetime-local']: string;
	} = {
		date: 'yyyy-MM-dd',
		'datetime-local': "yyyy-MM-dd'T'HH:mm",
	},
): string | null => {
	if (!date) {
		return null;
	}
	return DateTime.fromJSDate(new Date(date)).toFormat(formatMap[type]);
};

export const getOnlyDateFromJsDate = (date: Date | string): Date => {
	const newDate = new Date(date);
	newDate.setHours(0, 0, 0, 0);
	return newDate;
};

/**
 * Convert input value from event target to target type
 * @param input input element
 * @param type target type
 * @returns converted value
 */
export const convertInputValue = <T>(input: EventTarget & HTMLInputElement, type: IInputFieldProps<T>['type']): T => {
	switch (type) {
		case 'number':
			return input.valueAsNumber as T;
		case 'checkbox':
		case 'radio':
			return input.checked as T;
		case 'date':
		case 'datetime-local':
			return (input.value ? new Date(input.value) : null) as T;
		default:
			return input.value as T;
	}
};
