import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';

export interface IAccidentDeleteParams extends IServiceBaseParams {
	documentNumber: string;
	provider: string;
}

export const accidentDeleteService = (model: IAccidentDeleteParams) => {
	return Api.post<IAccident, IAccidentDeleteParams>(AccidentEndpoints.DELETE, model);
};
