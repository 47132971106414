import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderInsertParams extends IServiceBaseParams {
	customer: string;
	orderDate: string;
	plateNumbers: string[];
}

/**
 * Inserts a new vehicle sale order.
 * @param params - Parameters for the insertion of the sale order.
 * @returns A promise that resolves to the inserted sale order.
 */
export const saleOrderInsertService = (params: ISaleOrderInsertParams) => {
	return Api.post<ISaleOrder, ISaleOrderInsertParams>(SaleOrderEndPoints.INSERT, params);
};
