import React from 'react';

import type ReactHookForm from 'react-hook-form';

import { getErrorDescription } from './helpers';

interface IFieldErrorProps {
	// The id of the field to display the error message for
	id: string;
	// The name of the field to display the error message for
	name: string;
	// The error object from field state
	error?: ReactHookForm.FieldError;
}

/**
 * Component that renders an error message for a form field.
 * @param param0 - The properties for the FieldError component
 * @returns The rendered FieldError component
 */
export const FieldError: React.FC<IFieldErrorProps> = ({ id, name, error }) => {
	if (!error) {
		return null;
	}

	const message = error.message || getErrorDescription(name, error);
	return (
		<div className="invalid-feedback d-block" id={`${id}-invalid-feedback`}>
			{message}
		</div>
	);
};
