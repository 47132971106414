import { vehicleServiceSearch } from '../../../../data/services/VehicleService';
import type { IVehicleSearchParams } from '../../../../models/serviceParams/VehicleParams';
import { createSyncAction } from '../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../../../modules/vehicle/entities/Vehicle';
import type { ISalePriceModifyChangeAmountParams } from '../services/SalePriceModifyService';
import { salePriceModifyChangePriceService } from '../services/SalePriceModifyService';

/**
 * Searches for vehicles based on search parameters.
 * @param {IVehicleSearchParams} params The parameters used for searching vehicles.
 * @returns {Promise<void>} A promise that resolves when the search operation is complete.
 */
export const salePriceModifySearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'sale/priceModify/search',
	vehicleServiceSearch,
);

export const salePriceModifyClear = createSyncAction('sale/priceModify/clear');

export const salePriceModifyChangePrice = createAsyncAction<IVehicle[], ISalePriceModifyChangeAmountParams>(
	'sale/priceModify/modify',
	salePriceModifyChangePriceService,
);
