import type { CSSObjectWithLabel } from 'react-select';
import { styled } from 'styled-components';

export const SelectAllLink = styled('span')`
	float: right;
	cursor: pointer;

	:hover {
		color: #1356ba;
	}
`;

export const selectStyles = {
	control: (base: CSSObjectWithLabel) => ({
		...base,
		cursor: 'pointer',
		border: '1px solid #c2cfd6',
		borderRadius: 0,
		maxHeight: '300px',
		overflow: 'auto',
	}),
	menu: (base: CSSObjectWithLabel) => ({
		...base,
		zIndex: 1030,
		borderRadius: 0,
	}),
	option: (base: CSSObjectWithLabel) => ({
		...base,
		cursor: 'pointer',
	}),
	singleValue: (base: CSSObjectWithLabel) => {
		const transition = 'opacity 300ms';

		return {
			...base,
			cursor: 'pointer',
			transition,
		};
	},
};
