import { VehicleEndPoints } from '../../../../data/endPoints/VehicleEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../../modules/vehicle/entities/Vehicle';

export interface ISalePriceModifyChangeAmountParams extends IServiceBaseParams {
	saleAmount: number;
	plateNumbers: string[];
}

export const salePriceModifyChangePriceService = (model: ISalePriceModifyChangeAmountParams) => {
	return Api.post<IVehicle[], ISalePriceModifyChangeAmountParams>(VehicleEndPoints.CHANGE_SALE_PRICE, model);
};
