import { AccidentEndpoints } from './AccidentEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IAccident } from '../entities/Accident';

export interface IAccidentGetByAccidentPartParams extends IServiceBaseParams {
	accidentCode: string;
}

export const accidentGetByAccidentPartService = (model: IAccidentGetByAccidentPartParams) => {
	return Api.get<IAccident[], IAccidentGetByAccidentPartParams>(AccidentEndpoints.GET_BY_ACCIDENT_PART, model);
};
