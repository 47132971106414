import React from 'react';

import classNames from 'classnames';
import type { ControllerProps } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';

import { useInputFieldTextProps } from './hooks';
import type { IInputFieldProps, IInputTextProps } from './types';

export const InputFieldText: React.FC<IInputFieldProps<string> | IInputTextProps> = ({
	component: Component = 'input',
	innerRef,
	...rest
}) => {
	/* Hooks */
	const { onChange, onBlur, validation, onRenderRight, ...props } = useInputFieldTextProps(rest);
	const { name } = props;

	const { control, formState, getFieldState } = useFormContext();

	const { field } = useController({
		name,
		control,
		rules: validation as ControllerProps['rules'],
	});

	/* Handlers */
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = null;
		if (typeof event === 'object' && 'target' in event) {
			// eslint-disable-next-line prefer-destructuring
			value = event.target.value;
		} else {
			value = event;
		}

		field.onChange(event);
		if (onChange) {
			onChange(value);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if ((rest as IInputTextProps).onlyDigits) {
			const { key } = event;
			if (
				key === 'Backspace' ||
				key === 'Tab' ||
				key === 'ArrowLeft' ||
				key === 'ArrowRight' ||
				key === 'Delete' ||
				key === 'Enter'
			) {
				return;
			}
			if (!/^\d$/u.test(key)) {
				event.preventDefault();
			}
		}
	};

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		field.onBlur();
		if (onBlur) {
			onBlur(event);
		}
	};

	/* Constants */
	const { error } = getFieldState(name, formState);
	const componentClassName = classNames('form-control', {
		'is-invalid': Boolean(error),
	});

	const value = field.value ?? '';

	const handleRef = (ref: HTMLInputElement | null) => {
		field.ref(ref);
		if (innerRef) {
			innerRef.current = ref;
		}
	};

	return (
		<div className={onRenderRight ? 'd-flex' : ''}>
			<Component
				{...props}
				className={componentClassName}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
				onKeyDown={handleKeyDown}
				ref={handleRef}
				value={value}
			/>
			{onRenderRight}
		</div>
	);
};
