import type { IPresent } from '../../models/entities/Present';
import type {
	IEmailSendBookingAgencyParams,
	IEmailSendContactFormBusinessParams,
	IEmailSendContactFormBuyCarParams,
	IEmailSendContactFormFranchiseParams,
	IEmailSendCouponByCustomerCodeParams,
	IEmailSendDocumentParams,
	IEmailSendFeedBackParams,
	IEmailSendFiturFormParams,
} from '../../models/serviceParams/EmailParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { EmailEndPoints } from '../endPoints/EmailEndPoints';

/**
 * Send email document
 *  {*} document
 */
export const emailServiceSendDocument = (model: IEmailSendDocumentParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendDocumentParams>(EmailEndPoints.SEND_DOCUMENT, model);
};

/**
 * Send email feedBack
 *  {*} feedback
 * @example feedback
 * {
 * "json": "string",
 * "files": [],
 * "mailTo": "emai1;email2"
 * }
 */
export const emailServiceSendFeedBack = (model: IEmailSendFeedBackParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendFeedBackParams>(EmailEndPoints.SEND_FEEDBACK, model);
};

/**
 * Send email to agency
 *  {*} document
 */
export const emailServiceSendBookingAgency = (
	model: IEmailSendBookingAgencyParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendBookingAgencyParams>(EmailEndPoints.SEND_BOOKING_AGENCY, model);
};

/**
 * Send email with the coupons of the customer
 *  {*} document
 */
export const emailServiceSendCouponByCustomerCode = (
	model: IEmailSendCouponByCustomerCodeParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendCouponByCustomerCodeParams>(EmailEndPoints.SEND_COUPON_BY_CUSTOMER_CODE, model);
};

/**
 * It's a function that returns a promise that resolves to an ServiceResponse<boolean>
 * @param {IEmailSendContactFormBuyCarParams} model - IEmailSendCustomerFormBuyCarParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const emailServiceContactFormBuyCar = (
	model: IEmailSendContactFormBuyCarParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendContactFormBuyCarParams>(EmailEndPoints.SEND_CONTACT_FORM_BUY_CAR, model);
};

/**
 * It sends a contact form to the business email address
 * @param {IEmailSendContactFormBusinessParams} model - IEmailSendContactFormBusinessParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const emailServiceSendContactFormBusiness = (
	model: IEmailSendContactFormBusinessParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendContactFormBusinessParams>(EmailEndPoints.SEND_CONTACT_FORM_BUSINESS, model);
};

export const emailServiceSendFiturForm = (model: IEmailSendFiturFormParams) => {
	return Api.post<IPresent, IEmailSendFiturFormParams>(EmailEndPoints.SEND_FITUR_FORM, model);
};

/**
 * It sends a contact form to the franchise email address
 * @param {IEmailSendContactFormFranchiseParams} model - IEmailSendContactFormFranchiseParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const emailServiceSendContactFormFranchise = (
	model: IEmailSendContactFormFranchiseParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendContactFormFranchiseParams>(EmailEndPoints.SEND_CONTACT_FORM_FRANCHISE, model);
};
