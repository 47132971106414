import { createSlice } from '@reduxjs/toolkit';

import {
	saleServiceRateDeactivate,
	saleServiceRateGetServiceRate,
	saleServiceRateInsert,
	saleServiceRateReactivate,
	saleServiceRateSearch,
	saleServiceRatesClear,
} from '@crac/core/modules/sale/saleServiceRate/state/SaleServiceRateActions';
import type { ISaleServiceRateStateType } from '@crac/core/modules/sale/saleServiceRate/state/SaleServiceRateStateType';

const initialState: ISaleServiceRateStateType = {
	saleServiceRate: undefined,
	saleServiceRates: [],
	saleServiceRateDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceRateGetServiceRateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceRateReactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleServiceRateSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const saleServiceRateSlice = createSlice({
	name: 'SaleServiceRatesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//* SEARCH
		builder.addCase(saleServiceRateSearch.pending, (state) => {
			return {
				...state,
				saleServiceRates: [],
				saleServiceRateSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceRateSearch.rejected, (state, action) => {
			return {
				...state,
				saleServiceRateSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceRateSearch.fulfilled, (state, action) => {
			return {
				...state,
				saleServiceRates: action.payload,
				saleServiceRateSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* GET MARGIN
		builder.addCase(saleServiceRateGetServiceRate.pending, (state) => {
			return {
				...state,
				saleServiceRate: undefined,
				saleServiceRateGetServiceRateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceRateGetServiceRate.rejected, (state, action) => {
			return {
				...state,
				saleServiceRateGetServiceRateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceRateGetServiceRate.fulfilled, (state, action) => {
			return {
				...state,
				saleServiceRate: action.payload,
				saleServiceRateGetServiceRateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* INSERT
		builder.addCase(saleServiceRateInsert.pending, (state) => {
			return {
				...state,
				saleServiceRateInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceRateInsert.rejected, (state, action) => {
			return {
				...state,
				saleServiceRateInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceRateInsert.fulfilled, (state, action) => {
			return {
				...state,
				saleServiceRates: [...state.saleServiceRates, ...action.payload],
				saleServiceRateInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* REACTIVATE
		builder.addCase(saleServiceRateReactivate.pending, (state) => {
			return {
				...state,
				saleServiceRateReactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceRateReactivate.rejected, (state, action) => {
			return {
				...state,
				saleServiceRateReactivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceRateReactivate.fulfilled, (state, action) => {
			const existingMargins = state.saleServiceRates.filter(
				(margin) => !action.payload.some((newMargin) => newMargin.id === margin.id),
			);

			return {
				...state,
				saleServiceRates: [...existingMargins, ...action.payload],
				saleServiceRateReactivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* DEACTIVATE
		builder.addCase(saleServiceRateDeactivate.pending, (state) => {
			return {
				...state,
				saleServiceRateDeactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleServiceRateDeactivate.rejected, (state, action) => {
			return {
				...state,
				saleServiceRateDeactivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleServiceRateDeactivate.fulfilled, (state, action) => {
			const existingMargins = state.saleServiceRates.filter(
				(margin) => !action.payload.some((newMargin) => newMargin.id === margin.id),
			);

			return {
				...state,
				saleServiceRates: [...existingMargins, ...action.payload],
				saleServiceRateDeactivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* CLEAR
		builder.addCase(saleServiceRatesClear, (state) => {
			return {
				...state,
				saleServiceRates: [],
			};
		});
	},
});

export const saleServiceRateReducer = saleServiceRateSlice.reducer;
