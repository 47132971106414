import {
	branchServiceGetAll,
	branchServiceGetByPartnerCodeAndType,
	branchServicesGetNearbyBranchesWithAvailability,
} from '../../data/services/BranchService';
import type { IBookingGetAvailabilityParams } from '../../models/serviceParams/BookingParams';
import type { IBranchGetByPartnerCodeAndTypeParams } from '../../models/serviceParams/BranchParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { IBranchGetAllPayloadType } from '../types/ActionsPayload';

/**
 * Retrieves all branches.
 * @param {IServiceBaseParams} IServiceBaseParams - The parameters for the service.
 * @returns {Promise<void>} A promise that resolves to an array of branches.
 */
export const branchGetAll = createAsyncAction<IBranchGetAllPayloadType, IServiceBaseParams | undefined>(
	'branch/getAll',
	async (params) => {
		const response = await branchServiceGetAll(params);

		return {
			errors: response.errors,
			ok: response.ok,
			data: {
				branches: response.data ?? [],
				locale: params.locale,
			},
		};
	},
);
/**
 * Retrieves branches by partner code and type.
 *
 * @param {string[]} payload `string[]` The payload for the action.
 * @param {IBranchGetByPartnerCodeAndTypeParams} IBranchGetByPartnerCodeAndTypeParams - The meta data for the action.
 * @returns {Promise<void>} - A promise that resolves to an array of branch code.
 */
export const branchGetByPartnerCodeAndType = createAsyncAction<string[], IBranchGetByPartnerCodeAndTypeParams>(
	'branch/getByPartnerCodeAndType',
	branchServiceGetByPartnerCodeAndType,
);

/**
 * Retrieves nearby branches with availability.
 *
 * @param {string[]} payload `string[]` The payload for the action.
 * @param {IBookingGetAvailabilityParams} IBookingGetAvailabilityParams - The parameters for getting availability.
 * @returns {Promise<void>} A promise that resolves when the nearby branches with availability are retrieved.
 */
export const branchGetNearbyBranchesWithAvailability = createAsyncAction<string[], IBookingGetAvailabilityParams>(
	'branch/getNearbyBranchesWithAvailability',
	branchServicesGetNearbyBranchesWithAvailability,
);

/**
 * Resets the nearby branches with availability.
 *
 * @param {string[]} payload `boolean` The payload for the action.
 * @param userClose - Indicates if the user is close to the branches.
 */
export const branchGetNearbyBranchesWithAvailabilityReset = createSyncAction<boolean | undefined>(
	'branch/getNearbyBranchesWithAvailabilityReset',
);
