export enum AccidentEndpoints {
	/**
	 * @host `/accident/getByBooking/`
	 */
	GET_BY_BOOKING = '/accident/getByBooking/',

	/**
	 * @host `/accident/getByVehicle/`
	 */
	GET_BY_VEHICLE = '/accident/getByVehicle/',

	/**
	 * @host `/accident/getByAccidentPart/`
	 */
	GET_BY_ACCIDENT_PART = '/accident/getByAccidentPart/',
	/**
	 * @host `accident/search/`
	 */
	SEARCH = '/accident/search/',
	/**
	 * @host `accident/insert/`
	 */
	INSERT = '/accident/insert/',
	/**
	 * @host `accident/modify/`
	 */
	MODIFY = '/accident/modify/',
	/**
	 * @host `accident/delete/`
	 */
	DELETE = '/accident/delete/',
	/**
	 * @host `accident/sendMail/`
	 */
	SEND_MAIL = '/accident/sendMail/',
}
