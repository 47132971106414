import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfig } = {};

dashboardRoutes[routesIdMap.IT] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.IT,
	name: 'IT',
	path: '/it',
	permissions: [PermissionsType.IT],
	icon: 'terminal',
	menuText: 'IT',
};
